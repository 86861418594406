import analytics from '../../analytics';
import { TAB_SHOW_EVENT } from '../tabs';
var getTracking = function () { return JSON.parse(document.querySelector('[data-tracking]').dataset.tracking); };
export default (function () {
    document.querySelectorAll('[role="tabpanel"]').forEach(function (tabPanel) {
        tabPanel.addEventListener(TAB_SHOW_EVENT, function (event) {
            var tabId = event.detail.id;
            if (tabId === 'tab-panel-product-details') {
                analytics.productTabDetail(getTracking());
            }
        });
    });
});
