import { initDropdowns } from '../dropdowns/lib';
import { MODAL_CLOSE_EVENT, MODAL_LOAD_EVENT } from '../dialogs/lib';
import addSelectDropdowns from '../dropdowns/select';
import analytics from '../../analytics';
var PositionType;
(function (PositionType) {
    PositionType["HEART"] = "heart";
    PositionType["RIGHT"] = "right";
})(PositionType || (PositionType = {}));
var formatCharacters = function (characters, symbols) {
    var value = '';
    for (var i = 0; i < characters.length; i += 1) {
        if (symbols.includes(characters[i])) {
            value += "<span class=\"c-preview-customisation__symbol\">".concat(characters[i], "</span>");
        }
        else {
            value += characters[i];
        }
    }
    return value;
};
export default function updateCustomisations() {
    document.querySelectorAll('[data-ajax-modal]').forEach(function (dialog) {
        dialog.addEventListener(MODAL_LOAD_EVENT, function (e) {
            if (e.detail.role === 'customisation') {
                var tracking = JSON.parse(dialog.querySelector('[data-tracking]').dataset.tracking);
                analytics.productCustomisationViewed(tracking);
                initDropdowns();
                addSelectDropdowns();
                // Sync message with photo preview if media exist
                var form = dialog.querySelector('form');
                var elements = form.elements;
                var textInputs_1 = form.querySelectorAll('[name="customizations[]"]');
                var symbolInputs = form.querySelectorAll('.c-product-symbols__label');
                var symbols_1 = Array.from(form.querySelectorAll('[name="symbol"]')).map(function (input) { return input.value; });
                var color_1 = elements.color;
                var position_1 = elements.position;
                var preview_1 = dialog.querySelector('[data-customisation-text]');
                var button_1 = form.querySelector('#addToCartCustomProductButton');
                var lastFocus_1 = form.querySelector('[name="customizations[]"]');
                var caretPos_1 = 0;
                var onInput_1 = function () {
                    var textContent = '';
                    textInputs_1.forEach(function (textInput) {
                        if (textInput.value !== '') {
                            textContent += "".concat(formatCharacters(textInput.value, symbols_1), "<br />");
                        }
                    });
                    if (textContent.length === 0) {
                        button_1.setAttribute('disabled', 'disabled');
                    }
                    else {
                        button_1.removeAttribute('disabled');
                    }
                    if (preview_1) {
                        preview_1.innerHTML = textContent;
                        if (color_1) {
                            var selectedNode = Array.from(color_1).find(function (node) { return node.checked; });
                            preview_1.style.color = selectedNode.dataset.color;
                        }
                        if (position_1) {
                            if (position_1.value === PositionType.HEART) {
                                preview_1.style.top = '36%';
                                preview_1.style.left = '55%';
                                preview_1.style.right = 'auto';
                            }
                            else if (position_1.value === PositionType.RIGHT) {
                                preview_1.style.top = '36%';
                                preview_1.style.left = 'auto';
                                preview_1.style.right = '55%';
                            }
                        }
                    }
                };
                var addSymbol_1 = function (addSymbolEvent) {
                    var originalText = lastFocus_1.value;
                    if (originalText.length >= lastFocus_1.maxLength) {
                        return;
                    }
                    var begin = originalText.substring(0, caretPos_1);
                    var end = originalText.substring(caretPos_1, originalText.length);
                    lastFocus_1.value = begin + addSymbolEvent.currentTarget.innerText + end;
                    onInput_1();
                    caretPos_1 += 1;
                };
                var updateLastFocus_1 = function (updateLastFocusEvent) {
                    lastFocus_1 = updateLastFocusEvent.currentTarget;
                    caretPos_1 = lastFocus_1.selectionStart;
                };
                textInputs_1.forEach(function (textInput) {
                    textInput.addEventListener('blur', updateLastFocus_1);
                });
                symbolInputs.forEach(function (symbolInput) {
                    symbolInput.addEventListener('click', addSymbol_1);
                });
                form.addEventListener('input', onInput_1);
                onInput_1();
            }
        });
        dialog.addEventListener(MODAL_CLOSE_EVENT, function (e) {
            if (e.detail.role === 'customisation') {
                var tracking = JSON.parse(dialog.querySelector('[data-tracking]').dataset.tracking);
                analytics.productCustomisationClosed(tracking);
            }
        });
    });
}
