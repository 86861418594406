var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable no-use-before-define, no-restricted-globals, import/no-cycle, @typescript-eslint/no-use-before-define */
// @ts-expect-error
import Dropdowns from 'a11y-dropdown-component';
import addSelectDropdowns from '../dropdowns/select';
import addLazyLoad from '../lazyLoad/index';
import addState from '../states/index';
import addTabs from '../tabs/index';
import zoom from '../zoom/index';
import radioCheckbox from '../product/radioCheckbox';
import { VARIANT_SWITCH } from '../../events';
import { renderMultiProducts } from './MultiProducts';
export function switchVariants(url_1, variantIds_1) {
    return __awaiter(this, arguments, void 0, function (url, variantIds, shouldUpdateSlider) {
        var html;
        if (shouldUpdateSlider === void 0) { shouldUpdateSlider = true; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(url + '&ajax=true', { headers: { 'X-Requested-With': 'XMLHttpRequest' } }).then(function (response) {
                        return response.ok ? response.text() : Promise.resolve('');
                    })];
                case 1:
                    html = _a.sent();
                    if (!html) {
                        return [2 /*return*/];
                    }
                    document.querySelector('[data-multi-product]').outerHTML = html;
                    document.dispatchEvent(new CustomEvent(VARIANT_SWITCH, {
                        detail: {
                            variantIds: variantIds,
                            shouldUpdateSlider: shouldUpdateSlider,
                        },
                    }));
                    // Reload JS scripts
                    Dropdowns.init();
                    addSelectDropdowns();
                    addLazyLoad();
                    addTabs();
                    addState();
                    radioCheckbox();
                    zoom();
                    multiProductsSwitcher();
                    renderMultiProducts();
                    return [2 /*return*/];
            }
        });
    });
}
export function switchVariantsFromSlider(variantIds) {
    variantIds.find(function (variantId) {
        var variantLink = document.querySelector("a[data-multiproduct-variant-reload-id=\"".concat(variantId, "\"]"));
        if (variantLink) {
            switchVariants(variantLink.href, variantIds, false);
            return variantId;
        }
        return false;
    });
}
export default function multiProductsSwitcher() {
    var variantLinks = document.querySelectorAll('a[data-multiproduct-variant-reload-id]');
    variantLinks.forEach(function (link) {
        link.addEventListener('click', function (e) {
            var _a;
            e.preventDefault();
            var url = e.target.href;
            var multiProductVariantReloadId = e.target.dataset.multiproductVariantReloadId;
            var slides = Array.from(document.querySelector('.js-slider-product').querySelectorAll('.c-slider__item'));
            var variantIds = JSON.parse((_a = slides.find(function (slide) { return slide.dataset.variantIds.includes(multiProductVariantReloadId); })) === null || _a === void 0 ? void 0 : _a.dataset.variantIds);
            switchVariants(url, variantIds, true);
        });
    });
}
