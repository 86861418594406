import Dialogs from '../dialogs/a11y-dialog-component';

const DIALOG_AVAILABLE_SOON_ID = 'dialog-available-soon';
const DIALOG_AVAILABLE_SOON_FOR_VARIANT_ID = 'dialog-available-soon-for-variant';

export default function init() {
  const dialogAvailableSoon = document.getElementById(DIALOG_AVAILABLE_SOON_ID);
  const dialogAvailableSoonForVariant = document.getElementById(DIALOG_AVAILABLE_SOON_FOR_VARIANT_ID);

  if (dialogAvailableSoon !== null) {
    Dialogs.create(DIALOG_AVAILABLE_SOON_ID);
  }

  if (dialogAvailableSoonForVariant !== null) {
    Dialogs.create(DIALOG_AVAILABLE_SOON_FOR_VARIANT_ID);

    document.querySelector('body').addEventListener('click', (event) => {
      document.querySelectorAll('button.js-product-dialog').forEach((button) => {
        if (event.target === button) {
          document.querySelector(`#${DIALOG_AVAILABLE_SOON_FOR_VARIANT_ID} a`).href = button.dataset.link;
          Dialogs.show(DIALOG_AVAILABLE_SOON_FOR_VARIANT_ID);
        }
      });
    });
  }
}
