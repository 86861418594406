import GoogleMapsLoader from '../../../googlemaps/index';

export default function initOrderSummaryAddressAutocomplete(callback) {
  GoogleMapsLoader.load((google) => {
    const containers = document.querySelectorAll('input.geocoding');
    containers.forEach((container) => {
      const autocomplete = new google.maps.places.Autocomplete(container, { types: ['geocode'] });
      autocomplete.addListener('place_changed', () => {
        callback(container, autocomplete.getPlace());
      });
      // Hack wtf to disable autocomplete on chrome 60+, conflicted with Google Place suggestion
      container.addEventListener('focus', () => {
        container.setAttribute('autocomplete', 'new-password');
      });
    });
  });
}
