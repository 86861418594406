/* Addresses management
 ========================================================================== */

import setAccountNavigation from './navigation';
import { initDropdowns } from '../dropdowns/lib';
import initOrderSummaryAddressAutocomplete from '../forms/order/summary/geocoding';
import addDropdowns from '../dropdowns';
import { hideOnClickOutside } from '../../utils';

const domParser = new DOMParser();

function autocomplete(query = {}) {
  const queryParams = new URLSearchParams();
  const languageCode = document.documentElement.lang.split('-')[0];

  queryParams.append('id', 'AlLTRxsiWYqeyjN1W7ur');
  queryParams.append('languageCode', languageCode);

  Object.entries(query).forEach(([name, value]) => queryParams.append(name, value));

  return fetch(`https://dev.capadresse.com/?${queryParams.toString()}`, {
    method: 'GET',
    headers: {
      Authorization: 'Basic U0VaQU5FOi5oV3llbTh3PllfcWpnKyEtbk8lYWEodzNtLiZ8LjB2',
    },
  }).then((response) => response.json());
}

const addAutocompleteItem = (list, content, data = {}, callback) => {
  const li = `<li class="c-autocomplete__item" role="option">
    <span class="c-autocomplete__link">
      ${content}
    </span>
  </li>`;

  const element = domParser.parseFromString(li, 'text/html').body.childNodes[0];
  Object.entries(data).forEach(([name, value]) => {
    element.firstElementChild.dataset[name] = value;
  });
  element.addEventListener('click', callback);
  list.append(element);
  list.classList.remove('u-hidden');
};

export default function addressesManagement() {
  const container = document.querySelector('.AddressSection');
  if (container === null) {
    return false;
  }

  container.dataset.autocompleteMode = '1';

  const getCountryCode = () => {
    return container.querySelector('#dropdown-user_address_countryCode input:checked')?.dataset.countryCode;
  };

  const View = {
    userAdresscountryCodes: container?.querySelectorAll('input[name="user_address[countryCode]"]'),
    userAdressPostcode: container?.querySelector('input[name="user_address[postcode]"]'),
    userAdressPostcodeSuggest: container.querySelector('.postcode-suggest'),
    userAdressCity: container?.querySelector('input[name="user_address[city]"]'),
    userAdresseCitySuggest: container.querySelector('.city-suggest'),
    userAdressAdress: container?.querySelector('input[name="user_address[address]"]'),
    userAdressAdressSuggest: container.querySelector('.address-suggest'),
  };

  // We fetch the localityId from CapAddress when the user edit an address which already exists
  if (
    View.userAdressPostcode !== null &&
    View.userAdressCity !== null &&
    View.userAdressPostcode.value.length > 0 &&
    View.userAdressCity.value.length > 0 &&
    !View.userAdressCity.dataset.cityId
  ) {
    const countryCode = getCountryCode();

    autocomplete({
      request: 'SearchAddress',
      step: 'SearchLocality',
      countryCode,
      inputOutput: `${View.userAdressPostcode.value} ${View.userAdressCity.value}`,
    }).then((body) => {
      if (body.returnValue === 0 || body.returnValue === 3) {
        // We keep the localityId only if the webservice send back two entries (with one forced) else we desactivate the autocomplete mode
        if (body.addresses && body.addresses.address.length === 2) {
          View.userAdressCity.dataset.cityId = body.addresses.address[0].localityId;
        } else {
          container.dataset.autocompleteMode = '0';
        }
      }
    });
  }

  const selectAddress = (event) => {
    const selected = event.target;
    View.userAdressAdress.value = selected.dataset.address;
    View.userAdressAdressSuggest.innerHTML = '';
    View.userAdressAdressSuggest.classList.add('u-hidden');
  };

  const selectPostcode = (event) => {
    const selected = event.target;
    View.userAdressPostcode.value = selected.dataset.postcode;
    View.userAdressCity.value = selected.dataset.city;
    View.userAdressCity.dataset.cityId = selected.dataset.cityId;
    document.querySelectorAll('.postcode-suggest, .city-suggest').forEach((suggestItem) => {
      suggestItem.innerHTML = '';
      suggestItem.classList.add('u-hidden');
    });
  };

  if (View.userAdresscountryCodes.length > 0 && !getCountryCode()) {
    View.userAdressPostcode.disabled = true;
    View.userAdressCity.disabled = true;
    View.userAdressAdress.disabled = true;

    View.userAdresscountryCodes.forEach((radio) => {
      radio.addEventListener('change', () => {
        View.userAdressPostcode.disabled = false;
        View.userAdressCity.disabled = false;
        View.userAdressAdress.disabled = false;
      });
    });
  }

  const countryCodeList = container.querySelector('.js-select-user_address_countryCode');

  if (countryCodeList !== null) {
    countryCodeList.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', (event) => {
        const countryCode = event.target.dataset.countryCode;

        autocomplete({
          request: 'IsCountryAvailable',
          countryCode,
          engine: 'line',
        }).then((body) => {
          if (body.returnValue !== 0 || body.isAvailable === 0) {
            container.dataset.autocompleteMode = '0';
          }
        });
        // Get and replace available states
        const addressForm = document.querySelector('.addressForm');

        const formData = new FormData();
        formData.append('user_address[countryCode]', getCountryCode());
        fetch(`${addressForm.getAttribute('action')}`, {
          method: addressForm.getAttribute('method'),
          body: formData,
        }).then((res) =>
          res.text().then((html) => {
            const parser = new DOMParser();
            const htmlDocument = parser.parseFromString(html, 'text/html');
            const stateSelectFromData = htmlDocument.querySelector('#countryCodeAndStateSection');
            const partToReplace = document.querySelector('#countryCodeAndStateSection');
            partToReplace.replaceWith(stateSelectFromData);
            initDropdowns();
            addDropdowns();
          }),
        );
      });
    });
  }

  if (View.userAdressPostcode !== null) {
    hideOnClickOutside(View.userAdressPostcodeSuggest);
    View.userAdressPostcode.addEventListener('input', (event) => {
      View.userAdressPostcodeSuggest.innerHTML = '';
      View.userAdressPostcodeSuggest.classList.add('u-hidden');

      if (container.dataset.autocompleteMode !== '1') {
        return;
      }

      const postcodeTyped = event.target.value;
      const countryCode = getCountryCode();

      autocomplete({
        request: 'SearchAddress',
        step: 'SearchLocality',
        countryCode,
        inputOutput: postcodeTyped,
      }).then((body) => {
        if (body.returnValue === 0 || body.returnValue === 3) {
          if (body.addresses && body.addresses.address.length > 1) {
            body.addresses.address.forEach((suggestedPostCode) => {
              if (suggestedPostCode.step !== 'Forced') {
                addAutocompleteItem(
                  View.userAdressPostcodeSuggest,
                  suggestedPostCode.inputOutput,
                  {
                    postcode: suggestedPostCode.postalCode,
                    city: suggestedPostCode.locality,
                    cityId: suggestedPostCode.localityId,
                  },
                  selectPostcode,
                );
              }
            });
          }
        }
      });
    });
  }

  if (View.userAdressCity !== null) {
    hideOnClickOutside(View.userAdresseCitySuggest);
    View.userAdressCity.addEventListener('input', (event) => {
      const suggest = container.querySelector('.city-suggest');
      View.userAdresseCitySuggest.innerHTML = '';
      View.userAdresseCitySuggest.classList.add('u-hidden');

      if (container.dataset.autocompleteMode !== '1') {
        return;
      }

      const cityTyped = event.target.value;
      const countryCode = getCountryCode();

      autocomplete({
        request: 'SearchAddress',
        step: 'SearchLocality',
        countryCode,
        inputOutput: cityTyped,
      }).then((body) => {
        if (body.returnValue === 0 || body.returnValue === 3) {
          if (body.addresses && body.addresses.address.length > 1) {
            body.addresses.address.forEach((suggestedPostCode) => {
              if (suggestedPostCode.step !== 'Forced') {
                addAutocompleteItem(
                  suggest,
                  suggestedPostCode.inputOutput,
                  {
                    postcode: suggestedPostCode.postalCode,
                    city: suggestedPostCode.locality,
                    cityId: suggestedPostCode.localityId,
                  },
                  selectPostcode,
                );
              }
            });
          }
        }
      });
    });
  }

  if (View.userAdressAdress !== null) {
    hideOnClickOutside(View.userAdressAdressSuggest);
    View.userAdressAdress.addEventListener('input', (event) => {
      View.userAdressAdressSuggest.innerHTML = '';
      View.userAdressAdressSuggest.classList.add('u-hidden');

      if (container.dataset.autocompleteMode !== '1') {
        return;
      }

      const addressTyped = event.target.value;

      if (addressTyped.length < 4) {
        return;
      }

      const countryCode = getCountryCode();
      const localityId = container.querySelector('#user_address_city').dataset.cityId;
      const locality = container.querySelector('#user_address_city').value;
      const postalCode = container.querySelector('#user_address_postcode').value;

      autocomplete({
        request: 'SearchAddress',
        step: 'SearchStreet',
        countryCode,
        inputOutput: addressTyped,
        qualityCode: 41,
        localityId,
        postalCode,
        locality,
      }).then((body) => {
        if (body.addresses && body.addresses.address.length > 0) {
          body.addresses.address.forEach((suggestedAddress) => {
            if (
              suggestedAddress.inputOutput &&
              (!suggestedAddress.postalCode || suggestedAddress.postalCode === postalCode)
            ) {
              let suggestion = suggestedAddress.inputOutput;
              if (suggestedAddress.inputOutput.indexOf('\t') >= 0) {
                suggestion = suggestion.substr(0, suggestion.indexOf('\t'));
              }
              addAutocompleteItem(
                View.userAdressAdressSuggest,
                suggestion,
                {
                  address: suggestion,
                },
                selectAddress,
              );
            }
          });
        }
      });
    });
  }

  const removeLinks = container.querySelectorAll('.removeAddress');
  removeLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      // eslint-disable-next-line
      if (window.confirm(link.dataset.message) === true) {
        fetch(link.href, {
          method: 'POST',
        })
          .then((response) => response.text())
          .then((body) => {
            container.outerHTML = body;
            setAccountNavigation();
            addressesManagement();
          });
      }
    });
  });

  const editLinks = container.querySelectorAll('.editAddress');
  editLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      fetch(link.href, {
        method: 'GET',
      })
        .then((response) => response.text())
        .then((body) => {
          container.outerHTML = body;
          setAccountNavigation();
          addressesManagement();

          const addressForm = document.querySelector('.addressForm');
          if (addressForm) {
            window.scrollTo({
              top: window.scrollY + addressForm.getBoundingClientRect().top,
              behavior: 'smooth',
            });
          }

          initDropdowns();
          addDropdowns();
          // initAccount();
        });
    });
  });

  const forms = document.querySelectorAll('body .addressForm');
  forms.forEach((form) => {
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      event.stopPropagation();
      fetch(form.action, {
        method: 'POST',
        body: new FormData(form),
      })
        .then((response) => response.text())
        .then((body) => {
          container.outerHTML = body;
          document.querySelector('.AddressSection input[name="user_address[city]"]').dataset.cityId =
            View.userAdressCity.dataset.cityId;
          setAccountNavigation();
          addressesManagement();

          initDropdowns();
          addDropdowns();
          // initAccount();
        });
    });
  });

  initOrderSummaryAddressAutocomplete((node, place) => {
    const closestPostCodeField = node.closest('section').querySelector('input[name*=postcode]');
    const closestCityField = node.closest('section').querySelector('input[name*=city]');
    const closestCountryField = node.closest('section').querySelectorAll('input[name*=country]');

    place.address_components.forEach((component) => {
      component.types.forEach((type) => {
        if (type === 'postal_code') {
          closestPostCodeField.value = component.long_name;
        } else if (type === 'locality') {
          closestCityField.value = component.long_name;
        } else if (type === 'country') {
          closestCountryField.forEach((field) => {
            if (field.value === component.long_name) {
              field.setAttribute('checked', 'checked');
            } else {
              field.removeAttribute('checked');
            }
          });
        }
      });
    });
  });

  // Address toggle
  const addressToggles = [...container.querySelectorAll('a[data-address-details-trigger="toggle"]')];
  addressToggles.forEach((toggle) => {
    toggle.addEventListener('click', () => {
      container.querySelectorAll('div[data-address-details]').forEach((addressBlock) => {
        addressBlock.classList.remove('u-hidden');
      });
      container.querySelector('.js-account-address-complementary-new-trigger').classList.add('u-hidden');
    });
  });

  return true;
}
