import debounce from 'lodash.debounce';

import routes from '../../routes';
import Dialogs from '../dialogs/a11y-dialog-component';
import isMobileDisplay from '../mobile/check';
import analytics from '../../analytics';

const DIALOG_WISHLIST_DISABLED_ID = 'dialog-wishlist-disabled';
const DIALOG_WISHLIST_ADD_ID = 'dialog-wishlist';

const WISHLIST_ITEMS = window.userData?.wishlistItems || [];

function setState(wishlistComponent, state = 'remove') {
  const toggleButton = wishlistComponent.querySelector('button');
  const tooltip = document.getElementById(toggleButton.getAttribute('aria-describedby'));

  if (state === 'remove') {
    if (!WISHLIST_ITEMS.includes(wishlistComponent.dataset.wishlistProductEan)) {
      WISHLIST_ITEMS.push(wishlistComponent.dataset.wishlistProductEan);
    }
    toggleButton.setAttribute('aria-pressed', 'true');
    tooltip.innerHTML = tooltip.dataset.wishlistTooltipRemove;
  } else {
    const index = WISHLIST_ITEMS.indexOf(wishlistComponent.dataset.wishlistProductEan);
    if (index !== -1) {
      WISHLIST_ITEMS.splice(index, 1);
    }
    toggleButton.setAttribute('aria-pressed', 'false');
    tooltip.innerHTML = tooltip.dataset.wishlistTooltipAdd;
  }
}

function setStateAll(component, state = 'remove') {
  document
    .querySelectorAll(
      `*[data-component="wishlist-item"][data-wishlist-product-ean="${component.dataset.wishlistProductEan}"]`,
    )
    .forEach((wishlistComponent) => {
      setState(wishlistComponent, state);
    });
}

function setInitialState(wishlistComponents) {
  wishlistComponents.forEach((wishlistComponent) => {
    if (window.userData && WISHLIST_ITEMS.includes(wishlistComponent.dataset.wishlistProductEan)) {
      setState(wishlistComponent);
    } else {
      setState(wishlistComponent, 'add');
    }
  });
}

function changeEanInEndpoint(endpoint) {
  const selectedInput = document.querySelector(
    `input[name="ean[${isMobileDisplay() ? 'mobile' : 'desktop'}][]"][type=checkbox]:checked`,
  );

  if (selectedInput) {
    return endpoint.substring(0, endpoint.lastIndexOf('/') + 1) + selectedInput.value;
  }

  return endpoint;
}

function updateProductImage (dialog, component) {
  const wishlistImage = dialog.querySelector('.js-dialog-wishlist-img-container img');
  wishlistImage.src = component.dataset.wishlistProductImg;
}

function add(component, endpoint) {
  const url = component.dataset.wishlistRetrieveSizeOnAdd === 'true' ? changeEanInEndpoint(endpoint) : endpoint;

  setStateAll(component, 'remove');

  fetch(url, { method: 'POST' }).then((response) => {
    if (!response.ok) {
      setStateAll(component, 'add');
      return;
    }

    const tracking = JSON.parse(component.dataset.tracking);
    const wishlistDialog = document.getElementById(DIALOG_WISHLIST_ADD_ID);

    if (wishlistDialog) {
      wishlistDialog.dataset.tracking = component.dataset.tracking;
      updateProductImage(wishlistDialog, component);
      Dialogs.show(DIALOG_WISHLIST_ADD_ID);
      analytics.popinWishlist(tracking);
    }

    analytics.productAddedWishlist(tracking);
  });
}

function remove(component, endpoint) {
  const url = component.dataset.wishlistRetrieveSizeOnAdd === 'true' ? changeEanInEndpoint(endpoint) : endpoint;

  setStateAll(component, 'add');

  fetch(url, { method: 'POST' }).then((response) => {
    if (!response.ok) {
      setStateAll(component, 'remove');
      return;
    }

    const tracking = JSON.parse(component.dataset.tracking);
    analytics.productRemovedWishlist(tracking);
  });
}

const DEBOUNCE_WAIT = 2000;
const DEBOUNCE_OPTIONS = { leading: true, trailing: false, maxWait: DEBOUNCE_WAIT }; // cf. https://lodash.com/docs/4.17.15#debounce

function handleToggle() {
  const body = document.querySelector('body');

  body.addEventListener(
    'click',
    debounce(
      (event) => {
        document.querySelectorAll('*[data-component="wishlist-item"]').forEach((wishlistComponent) => {
          if (
            event.target.closest('div.c-tooltip.u-ml-sm') !== null &&
            event.target.closest('div.c-tooltip.u-ml-sm').isSameNode(wishlistComponent)
          ) {
            const toggleButton = wishlistComponent.querySelector('button');

            if (!toggleButton) {
              return;
            }

            const dialogWishlistDisabled = document.getElementById(DIALOG_WISHLIST_DISABLED_ID);

            if (dialogWishlistDisabled !== null) {
              updateProductImage(dialogWishlistDisabled, wishlistComponent);
              Dialogs.show(DIALOG_WISHLIST_DISABLED_ID);

              setTimeout(() => {
                window.redirect(`${routes.connect}?redirect=${window.location.href}`);
              }, 3000);

              return;
            }

            toggleButton.attributes['aria-pressed'].nodeValue === 'true'
              ? remove(wishlistComponent, wishlistComponent.dataset.wishlistRemoveEndpoint)
              : add(wishlistComponent, wishlistComponent.dataset.wishlistAddEndpoint);
          }
        });
      },
      DEBOUNCE_WAIT,
      DEBOUNCE_OPTIONS,
    ),
  );
}

export function updateWishlist(ean) {
  const mainWishlist = document.querySelector('.js-product-content [data-wishlist-product-ean]');
  if (mainWishlist) {
    mainWishlist.dataset.wishlistProductEan = ean;
  }

  const wishlistComponents = document.querySelectorAll(`[data-wishlist-product-ean="${ean}"]`);

  if (wishlistComponents) {
    setInitialState(wishlistComponents);
  }
}

export default function toggleWishlistItem() {
  const wishlistComponents = document.querySelectorAll('*[data-component="wishlist-item"]');

  if (wishlistComponents.length === 0) return;

  const dialogWishlistDisabled = document.getElementById(DIALOG_WISHLIST_DISABLED_ID);

  if (dialogWishlistDisabled !== null) {
    Dialogs.create(DIALOG_WISHLIST_DISABLED_ID);
  } else {
    Dialogs.create(DIALOG_WISHLIST_ADD_ID, {
      onClose: (instance) => {
        document.getElementById(DIALOG_WISHLIST_ADD_ID).querySelector('.js-dialog-wishlist-img-container img').src = '';
        const tracking = JSON.parse(instance.dialog.dataset.tracking);
        analytics.popinWishlistClosed(tracking);
      },
    });
  }

  setInitialState(wishlistComponents);
  handleToggle();
}
