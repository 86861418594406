import analytics from '../../analytics';
import setCartVisibility from '../cart/setCartVisibility';

function formatDate(date) {
  const d = new Date(date);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  const year = d.getFullYear();

  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }
  return [year, month, day].join('-');
}

function checkDatePassed(selectedDate) {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const sendDateError = document.querySelector('[id=ecard_sendDate_error]');
  if (selectedDate < formatDate(now)) {
    sendDateError.classList.remove('u-hidden');
  } else {
    sendDateError.classList.add('u-hidden');
  }
}

const init = () => {
  const ecard = document.querySelector('[data-component="ecard-form"]');
  const sendDate = document.querySelector('[id=ecard_sendDate]');
  const submitButton = document.querySelector('button[type=submit]');

  if (sendDate) {
    sendDate.addEventListener('change', (event) => {
      const selectedDate = event.target.value;
      checkDatePassed(selectedDate);
    });
  }

  if (ecard && ecard.dataset.displayCart) {
    document.querySelectorAll('.js-cart').forEach((cart) => {
      setCartVisibility(cart);
    });
  }

  if (ecard) {
    ecard.querySelector('form').addEventListener('submit', (event) => {
      const data = Object.fromEntries(new FormData(event.target));
      analytics.giftCardAdded({
        recipient: data['ecard[for][first]'],
        amount: data['ecard[amountSelect]'],
      });

      if (submitButton) {
        submitButton.disabled = true;
      }
    });
  }
};

export default init;
