/* eslint-disable no-new */
/* eslint-disable import/no-cycle */
/* sliders
 ========================================================================== */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { A11y, Autoplay, Controller, EffectCoverflow, EffectFade, Mousewheel, Navigation, Pagination, Swiper, Thumbs, } from 'swiper';
import { SLIDE_CHANGE, VARIANT_SWITCH } from '../../events';
import { switchVariantsFromSlider } from '../multiProducts/switcher';
import { switchVariant } from '../product/switcher';
import config, { navigationConfig, paginationConfig } from './default';
Swiper.use([Thumbs, Navigation, Pagination, EffectFade, EffectCoverflow, Autoplay, Mousewheel, A11y, Controller]);
// horizontal slider (default)
export function horizontal() {
    var customConfig = {};
    var customNavigationConfig = {
        prevEl: '.js-slider-btn-prev',
        nextEl: '.js-slider-btn-next',
    };
    customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
    new Swiper('.js-slider', __assign(__assign({}, config), customConfig));
}
// vertical slider
export function vertical() {
    var customConfig = {
        direction: 'vertical',
    };
    var customNavigationConfig = {
        prevEl: '.js-slider-vertical-btn-prev',
        nextEl: '.js-slider-vertical-btn-next',
    };
    customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
    new Swiper('.js-slider-vertical', __assign(__assign({}, config), customConfig));
}
export function otherVariantsProduct() {
    var customConfig = {
        slidesPerView: 1.4,
        spaceBetween: 12,
        allowTouchMove: true,
        grabCursor: true,
        observer: true,
        observeParents: true,
        centeredSlides: true,
        mousewheel: {
            forceToAxis: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 3.51,
                spaceBetween: 18,
                allowTouchMove: true,
                grabCursor: false,
                centeredSlides: false,
            },
        },
    };
    new Swiper('.js-slider-other-variants-product', __assign(__assign({}, config), customConfig));
}
export function otherVariantsProductLoop() {
    var customConfig = {
        slidesPerView: 1.4,
        spaceBetween: 12,
        allowTouchMove: true,
        grabCursor: true,
        observer: true,
        observeParents: true,
        centeredSlides: true,
        loop: true,
        mousewheel: {
            forceToAxis: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 3.51,
                spaceBetween: 18,
                allowTouchMove: true,
                grabCursor: false,
                centeredSlides: false,
                loop: false,
            },
        },
    };
    new Swiper('.js-slider-other-variants-product-loop', __assign(__assign({}, config), customConfig));
}
// crosseling product slider
export function crosselingProduct() {
    var customConfig = {
        slidesPerView: 1.5,
        spaceBetween: 14,
        allowTouchMove: true,
        grabCursor: true,
        observer: true,
        observeParents: true,
        preventClicks: false,
        preventClicksPropagation: false,
        mousewheel: {
            forceToAxis: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 28,
                allowTouchMove: false,
                grabCursor: false,
            },
        },
    };
    new Swiper('.js-slider-crosseling-product', __assign(__assign({}, config), customConfig));
}
// centered crosseling product slider
export function centeredCrosselingProduct() {
    var customConfig = {
        slidesPerView: 2.25,
        spaceBetween: 14,
        allowTouchMove: true,
        grabCursor: true,
        centerInsufficientSlides: false,
        observer: true,
        observeParents: true,
        preventClicks: false,
        preventClicksPropagation: false,
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 28,
                allowTouchMove: false,
                grabCursor: false,
                centerInsufficientSlides: true,
            },
        },
    };
    new Swiper('.js-slider-centered-crosseling-product', __assign(__assign({}, config), customConfig));
}
// Product Slider
export function product() {
    var _a, _b;
    var isMultiProduct = !!document.querySelector('[data-multi-product]');
    // thumbs config
    var thumbsComponent = document.querySelector('.js-slider-thumbs');
    var thumbsSlides = Array.from(thumbsComponent.querySelectorAll('.c-slider__item'));
    var initialSlide = thumbsSlides.findIndex(function (el) { return !!el.dataset.selectedVariant; }) ||
        parseInt(thumbsComponent.dataset.initialSlide, 10) ||
        0;
    var slidesPerView = 5;
    var thumbsSlider = new Swiper('.js-slider-thumbs', __assign(__assign({}, config), { initialSlide: initialSlide, rewind: true, slidesPerView: slidesPerView, spaceBetween: parseInt(getComputedStyle(document.body).getPropertyValue('--swiper-space-between'), 10), watchSlidesProgress: true, allowTouchMove: false, breakpoints: {
            1025: {
                direction: 'vertical',
            },
        }, on: {
            init: function () {
                thumbsComponent.style.opacity = '1';
            },
        } }));
    // product config
    var customProductComponent = document.querySelector('.js-slider-product');
    if (!customProductComponent) {
        return;
    }
    var slides = Array.from(customProductComponent.querySelectorAll('.c-slider__item'));
    var productSlider = new Swiper('.js-slider-product', __assign(__assign({}, config), { navigation: __assign(__assign({}, navigationConfig), { prevEl: '.js-slider-product-btn-prev', nextEl: '.js-slider-product-btn-next' }), initialSlide: initialSlide, rewind: true, effect: 'fade', breakpoints: {
            1025: {
                allowTouchMove: false,
            },
        }, on: {
            init: function () {
                // In some cases the thumb slider is not initialized correctly
                setTimeout(function () {
                    productSlider.update();
                }, 400);
            },
        }, thumbs: {
            swiper: thumbsSlider,
            slideThumbActiveClass: 'is-active',
        }, pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            dynamicMainBullets: 5,
        } }));
    // Thumbs manual pagination
    (_a = document.querySelector('.js-slider-thumbs-btn-prev')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () {
        productSlider.slidePrev();
    });
    (_b = document.querySelector('.js-slider-thumbs-btn-next')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', function () {
        productSlider.slideNext();
    });
    productSlider.on('slideChangeTransitionStart', function () {
        // Pause all videos when a slide changes
        var videos = Array.from(customProductComponent.querySelectorAll('.js-slider-product-video'));
        videos.forEach(function (video) {
            video.pause();
        });
    });
    productSlider.on('slideChange', function (e) {
        var event = new Event(SLIDE_CHANGE);
        customProductComponent.dispatchEvent(event);
        var previousIndex = e.previousIndex;
        var currentIndex = e.realIndex;
        var previousSlide = slides[previousIndex];
        var slide = slides[currentIndex];
        var slideVideo = slide.querySelector('.js-slider-product-video');
        if (isMultiProduct) {
            var shouldSwitchVariant = !previousSlide || slide.dataset.variantIds !== previousSlide.dataset.variantIds;
            if (shouldSwitchVariant) {
                switchVariantsFromSlider(JSON.parse(slide.dataset.variantIds));
                productSlider.update();
            }
        }
        else {
            var shouldSwitchVariant = !previousSlide || slide.dataset.variantId !== previousSlide.dataset.variantId;
            if (shouldSwitchVariant) {
                switchVariant(slide.dataset.variantId, false);
                productSlider.update();
            }
        }
        // Play video when a slide is active
        setTimeout(function () {
            if (slideVideo) {
                // if video is loaded, play
                if (slideVideo.readyState === 4) {
                    slideVideo.play();
                }
                else {
                    // else wait for loaded state, then play
                    slideVideo.addEventListener('loadeddata', function () { return slideVideo.play(); }, { once: true });
                }
            }
        }, 0);
    });
    // Handle variant switch sync
    document.addEventListener(VARIANT_SWITCH, function (e) {
        if (!e.detail.shouldUpdateSlider) {
            return;
        }
        var index = slides.findIndex(function (el) {
            return e.detail.variantId
                ? el.dataset.variantId === e.detail.variantId
                : el.dataset.variantIds.includes(e.detail.variantIds.toString());
        });
        productSlider.slideTo(index, 0, false);
        thumbsSlider.slideTo(index, 0, false);
        productSlider.update();
    });
}
// loop slider
export function loop() {
    var customConfig = {
        loop: true,
    };
    var customNavigationConfig = {
        prevEl: '.js-slider-loop-btn-prev',
        nextEl: '.js-slider-loop-btn-next',
    };
    var customPaginationConfig = {
        el: '.js-slider-loop-pagination',
    };
    customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
    customConfig.pagination = __assign(__assign({}, paginationConfig), customPaginationConfig);
    new Swiper('.js-slider-loop', __assign(__assign({}, config), customConfig));
}
// loop autoplay slider
export function loopAutoplay() {
    var sliders = Array.from(document.querySelectorAll('.js-slider-loop-autoplay'));
    sliders.forEach(function (slider, index) {
        var customConfig = {};
        var slides = slider.querySelectorAll('.c-slider__item');
        if (slides.length > 1) {
            customConfig.loop = true;
            customConfig.autoplay = {
                delay: 3000,
                disableOnInteraction: false,
            };
        }
        var compositionId = slider.dataset.id;
        var videos = slider.querySelectorAll('video');
        var customNavigationConfig = {
            prevEl: ".js-slider-loop-autoplay-btn-prev-".concat(compositionId),
            nextEl: ".js-slider-loop-autoplay-btn-next-".concat(compositionId),
        };
        var customPaginationConfig = {
            el: ".js-slider-loop-autoplay-pagination-".concat(compositionId),
        };
        customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
        customConfig.pagination = __assign(__assign({}, paginationConfig), customPaginationConfig);
        var mySwiper = new Swiper(".js-slider-".concat(compositionId), __assign(__assign({}, config), customConfig));
        // Segmented control to select the menu color
        if (index === 0) {
            mySwiper.on('slideChangeTransitionEnd', function () {
                var event = new Event(SLIDE_CHANGE);
                slider.dispatchEvent(event);
            });
        }
        videos.forEach(function (video) {
            video.addEventListener('click', function () {
                video.paused ? mySwiper.autoplay.stop() : mySwiper.autoplay.start();
            });
        });
    });
}
// faq slider
export function faqSlider() {
    var breakpoint = window.matchMedia('(min-width: 48em)');
    var customConfig = {
        slidesPerView: 2,
        spaceBetween: 14,
        loop: true,
    };
    var customPaginationConfig = {
        el: '.js-slider-faq-pagination',
    };
    customConfig.pagination = __assign(__assign({}, paginationConfig), customPaginationConfig);
    var mySwiper;
    var enableSwiper = function () {
        mySwiper = new Swiper('.js-slider-faq', __assign(__assign({}, config), customConfig));
    };
    var breakpointChecker = function () {
        if (breakpoint.matches) {
            if (mySwiper)
                mySwiper.destroy(true, true);
        }
        else {
            enableSwiper();
        }
    };
    breakpointChecker();
    breakpoint.addListener(breakpointChecker);
}
// CMS Slider-3-visible-center
export function cmsSlider3Center() {
    var sliders = Array.from(document.querySelectorAll('.js-slider-cms-slider-3-visible-center'));
    sliders.forEach(function (slider) {
        var compositionId = slider.dataset.id;
        var customConfig = {
            slidesPerView: 1,
            spaceBetween: 32,
            allowTouchMove: true,
            grabCursor: true,
            preventClicks: false,
            preventClicksPropagation: false,
            loop: true,
            mousewheel: {
                forceToAxis: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    centeredSlides: true,
                },
            },
        };
        var textCustomConfig = {
            slidesPerView: 1,
            spaceBetween: 32,
            allowTouchMove: false,
            preventClicks: false,
            preventClicksPropagation: false,
            loop: true,
            mousewheel: {
                forceToAxis: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    centeredSlides: true,
                },
            },
        };
        var customNavigationConfig = {
            prevEl: ".js-slider-cms-slider-3-visible-center-btn-prev-".concat(compositionId),
            nextEl: ".js-slider-cms-slider-3-visible-center-btn-next-".concat(compositionId),
        };
        var customPaginationConfig = {
            el: ".js-slider-cms-slider-3-visible-center-pagination-".concat(compositionId),
            dynamicBullets: true,
            clickable: true,
            dynamicMainBullets: 2,
        };
        customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
        customConfig.pagination = customPaginationConfig;
        var imagesSwiper = new Swiper(".js-slider-cms-slider-3-visible-center-".concat(compositionId), __assign(__assign({}, config), customConfig));
        var textSwiper = new Swiper(".js-slider-cms-slider-3-visible-center-text-".concat(compositionId), __assign(__assign({}, config), textCustomConfig));
        imagesSwiper.controller.control = textSwiper;
        textSwiper.controller.control = imagesSwiper;
    });
}
// CMS LBM text 50 50 slider color - slider 2 center
export function cmsSlider2Center() {
    var sliders = Array.from(document.querySelectorAll('.js-slider-cms-slider-2-center'));
    sliders.forEach(function (slider) {
        var nbSlides = parseInt(slider.dataset.nbSlides, 10);
        var gap = parseInt(slider.style.getPropertyValue('--slider-gap'), 10);
        var compositionId = slider.dataset.id;
        var slidesPerView = nbSlides >= 6 ? 4 : 2.2;
        var breakpointLoop = nbSlides >= 8;
        var customConfig = {
            slidesPerView: 1,
            spaceBetween: gap,
            initialSlide: 1,
            loop: true,
            allowTouchMove: true,
            grabCursor: true,
            preventClicks: false,
            preventClicksPropagation: false,
            mousewheel: {
                forceToAxis: true,
            },
            pagination: {
                clickable: true,
                el: ".js-slider-cms-slider-2-center-pagination-".concat(compositionId),
                dynamicBullets: true,
                enabled: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: slidesPerView,
                    loop: breakpointLoop,
                    slidesPerGroup: 2,
                    initialSlide: 0,
                    pagination: {
                        enabled: false,
                    },
                },
            },
        };
        var customNavigationConfig = {
            prevEl: ".js-slider-cms-slider-2-center-btn-prev-".concat(compositionId),
            nextEl: ".js-slider-cms-slider-2-center-btn-next-".concat(compositionId),
        };
        customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
        new Swiper(".js-slider-".concat(compositionId), __assign(__assign({}, config), customConfig));
    });
}
// CMS Slider-4-visible
export function cmsSlider4() {
    var customConfig = {
        slidesPerView: 1.7,
        spaceBetween: 14,
        allowTouchMove: true,
        grabCursor: true,
        preventClicks: false,
        preventClicksPropagation: false,
        mousewheel: {
            forceToAxis: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 2.7,
            },
            1025: {
                slidesPerView: 3.7,
            },
        },
    };
    new Swiper('.js-slider-cms-slider-4-visible', __assign(__assign({}, config), customConfig));
}
// CMS Slider-5-visible
export function cmsSlider5() {
    var customConfig = {
        slidesPerView: 2.4,
        spaceBetween: 10,
        allowTouchMove: true,
        grabCursor: true,
        preventClicks: false,
        preventClicksPropagation: false,
        mousewheel: {
            forceToAxis: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 3.4,
                spaceBetween: 14,
            },
            1025: {
                slidesPerView: 4.4,
                spaceBetween: 14,
            },
        },
    };
    new Swiper('.js-slider-cms-slider-5-visible', __assign(__assign({}, config), customConfig));
}
// CMS Slider-7-visible
export function cmsSlider7() {
    var customConfig = {
        slidesPerView: 3.5,
        spaceBetween: 10,
        allowTouchMove: true,
        grabCursor: true,
        preventClicks: false,
        preventClicksPropagation: false,
        mousewheel: {
            forceToAxis: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 4.5,
                spaceBetween: 14,
            },
            1025: {
                slidesPerView: 6.5,
                spaceBetween: 14,
            },
        },
    };
    new Swiper('.js-slider-cms-slider-7-visible', __assign(__assign({}, config), customConfig));
}
// Cross sell
export function crossSell() {
    var customConfig = {
        slidesPerView: 1,
        spaceBetween: 28,
        allowTouchMove: true,
        grabCursor: true,
        observer: true,
        observeParents: true,
        preventClicks: false,
        preventClicksPropagation: false,
        mousewheel: {
            forceToAxis: true,
        },
    };
    var customNavigationConfig = {
        prevEl: '.js-slider-modal-cross-sell-btn-prev',
        nextEl: '.js-slider-modal-cross-sell-btn-next',
    };
    customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
    new Swiper('.js-slider-modal-cross-sell', __assign(__assign({}, config), customConfig));
}
// Shop the look
export function shopTheLook() {
    var customConfig = {
        slidesPerView: 1.4,
        spaceBetween: 14,
        allowTouchMove: true,
        grabCursor: true,
        observer: true,
        observeParents: true,
        preventClicks: false,
        preventClicksPropagation: false,
        centeredSlides: true,
        initialSlide: 1,
        mousewheel: {
            forceToAxis: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                centeredSlides: false,
                initialSlide: 0,
            },
            1280: {
                slidesPerView: 4,
                spaceBetween: 18,
                allowTouchMove: false,
                grabCursor: false,
                centeredSlides: false,
                initialSlide: 0,
            },
        },
    };
    var customNavigationConfig = {
        prevEl: '.js-slider-modal-shop-the-look-btn-prev',
        nextEl: '.js-slider-modal-shop-the-look-btn-next',
    };
    customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
    new Swiper('.js-slider-modal-shop-the-look', __assign(__assign({}, config), customConfig));
}
// CMS Block Product Slider
export function cmsBlockProduct() {
    var sliders = Array.from(document.querySelectorAll('.js-slider-block-product'));
    sliders.forEach(function (slider) {
        var customConfig = {};
        var slides = slider.querySelectorAll('.c-slider__item');
        if (slides.length > 1) {
            customConfig.loop = true;
        }
        var customNavigationConfig = {
            prevEl: slider.parentNode.querySelector('.c-slider__btn--prev'),
            nextEl: slider.parentNode.querySelector('.c-slider__btn--next'),
        };
        customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
        new Swiper(slider, __assign(__assign(__assign(__assign({}, config), { slidesPerView: 'auto' }), customConfig), { on: {
                slideChangeTransitionStart: function () {
                    var videos = Array.from(slider.querySelectorAll('video'));
                    // Pause all videos when a slide changes
                    videos.forEach(function (video) {
                        video.pause();
                    });
                },
                slideChangeTransitionEnd: function (e) {
                    var event = new Event(SLIDE_CHANGE);
                    slider.dispatchEvent(event);
                    var slide = e.slides[e.activeIndex];
                    var slideVideo = slide.querySelector('video');
                    // Play video when a slide is active
                    if (slideVideo) {
                        // if video is loaded, play
                        if (slideVideo.readyState >= 2) {
                            slideVideo.play();
                        }
                        else {
                            // else wait for loaded state, then play
                            slideVideo.addEventListener('loadeddata', function () { return slideVideo.play(); }, { once: true });
                        }
                    }
                },
            } }));
    });
}
export function crossSellOtherSizes() {
    var customConfig = {
        slidesPerView: 2,
        spaceBetween: 12,
        allowTouchMove: true,
        grabCursor: true,
        observer: true,
        observeParents: true,
        breakpoints: {
            768: {
                slidesPerView: 4,
                spaceBetween: 14,
                allowTouchMove: true,
                grabCursor: false,
            },
        },
    };
    new Swiper('.js-slider-other-sizes-product', __assign(__assign({}, config), customConfig));
}
// Lookbook Edito Slider 5
export function lookbookEditoSlider5() {
    var customConfig = {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        loop: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 120,
            modifier: 1,
            slideShadows: false,
        },
    };
    var customNavigationConfig = {
        prevEl: '.js-slider-btn-prev',
        nextEl: '.js-slider-btn-next',
    };
    customConfig.navigation = __assign(__assign({}, navigationConfig), customNavigationConfig);
    new Swiper('.js-slider-lbe-slider-5', __assign(__assign({}, config), customConfig));
}
