/* videos
 ========================================================================== */

export default function addVideos(context) {
  const functionContext = typeof context !== 'undefined' ? context : document;
  const videoContainers = functionContext.querySelectorAll('.js-video');

  const onClick = (video, e) => {
    e.stopPropagation();
    video.paused ? video.play() : video.pause();
  };

  const toggleVolume = (video, videoVolumeButton, e) => {
    e.stopPropagation();

    if (video.muted === true) {
      video.muted = false;
      videoVolumeButton.classList.add('is-active');
    } else if (video.muted === false) {
      video.muted = true;
      videoVolumeButton.classList.remove('is-active');
    }
  };

  videoContainers.forEach((videoContainer) => {
    const videos = videoContainer.querySelectorAll('video');

    videos.forEach((video) => {
      const videoPlayContainer = videoContainer.querySelector('.js-video-play');
      const videoVolumeButton = videoContainer.querySelector('.js-video-volume');

      if (videoPlayContainer) {
        videoPlayContainer.addEventListener('click', onClick.bind(this, video));
      }

      if (videoVolumeButton) {
        if (video.muted === false) {
          videoVolumeButton.classList.add('is-active');
        }

        videoVolumeButton.addEventListener('click', toggleVolume.bind(this, video, videoVolumeButton));
      }
    });
  });
}
