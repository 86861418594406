/* Orders cancellation
 ========================================================================== */

export default function removeCardForm() {
  const forms = document.querySelectorAll('.removeCardForm');
  const removeCardField = document.querySelector('.removeCardField');

  forms.forEach((form) => { // orderCancelField
    const submitAnchors = form.querySelectorAll('button[data-remove-card]');

    submitAnchors.forEach((anchor) => { // orderCancelField
      anchor.addEventListener('click', () => {
        // eslint-disable-next-line
        if (window.confirm(form.dataset.confirmMessage) === true) {
          removeCardField.value = anchor.dataset.removeCard;
          form.submit();
        }
      });
    });
  });
}

