import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { MultiProductCta } from './MultiProductCta';
export var MultiProducts = function (_a) {
    var multiProduct = _a.multiProduct;
    var product1 = multiProduct.products[0];
    var product2 = multiProduct.products[1];
    var selectedVariant1 = product1.selectedVariant;
    var selectedVariant2 = product2.selectedVariant;
    var _b = useState(selectedVariant1.selectedItem), selectedItem1 = _b[0], setSelectedItem1 = _b[1];
    var _c = useState(selectedVariant2.selectedItem), selectedItem2 = _c[0], setSelectedItem2 = _c[1];
    function selectSize(ean) {
        if (selectedVariant1.items.find(function (item) { return item.ean === ean; })) {
            setSelectedItem1(selectedVariant1.items.find(function (item) { return item.ean === ean; }));
        }
        else {
            setSelectedItem2(selectedVariant2.items.find(function (item) { return item.ean === ean; }));
        }
    }
    // Listen on size change event
    useEffect(function () {
        document.querySelectorAll('input[data-size]').forEach(function (sizeInput) {
            sizeInput.addEventListener('click', function (e) {
                var ean = e.target.value;
                selectSize(ean);
            });
        });
    }, []);
    return (React.createElement(MultiProductCta, { selectedVariant1: selectedVariant1, selectedVariant2: selectedVariant2, selectedItem1: selectedItem1, selectedItem2: selectedItem2 }));
};
export var renderMultiProducts = function () {
    document.querySelectorAll('[data-multi-product]').forEach(function (productContainer) {
        // The product state is either on the button (ie in modals) or global (product page)
        var product = JSON.parse(productContainer.dataset.multiProduct);
        productContainer.querySelectorAll('[data-multi-product-cta]').forEach(function (ctaContainer) {
            createRoot(ctaContainer).render(React.createElement(MultiProducts, { multiProduct: product }));
        });
    });
};
