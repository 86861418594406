/* cart/counter
 ========================================================================== */

export default function updateMobileCartCounter() {
  const counter = document.querySelector('.js-product-counter');
  if (!counter) {
    return;
  }

  const counterSelectors = ['.js-mobile-product-counter', '.js-sales-submenu-product-counter'];

  counterSelectors.forEach((selector) => {
    const counterToUpdate = document.querySelector(selector);
    if (counterToUpdate) {
      counterToUpdate.innerHTML = counter.innerHTML;
    }
  });
}
