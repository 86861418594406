/* product/lastSeen
 ========================================================================== */

const funcs = {
  /**
   * @param {string} site
   * @return {Array}
   */
  get: (site) => {
    let lastSeenProducts = window.localStorage.getItem(`lastSeenProducts-${site}`);
    if (lastSeenProducts === null) {
      lastSeenProducts = [];
    } else {
      lastSeenProducts = JSON.parse(lastSeenProducts);
    }

    return lastSeenProducts;
  },

  /**
   * @param {string} site
   * @param {Array} lastSeenProducts
   */
  save: (site, lastSeenProducts) => {
    lastSeenProducts.sort((a, b) => a.date < b.date);
    window.localStorage.setItem(`lastSeenProducts-${site}`, JSON.stringify(lastSeenProducts));
  },
};

export default funcs;
