/* sticky submenu
 ========================================================================== */

export default function addStickySubmenu() {
  const submenu = document.querySelector('.js-submenu');
  const submenuSticky = document.querySelector('.js-submenu-sticky');

  if (!submenu || !submenuSticky) return;

  const setState = () => {
    const submenuBoundingTop = submenu.getBoundingClientRect().top;
    const submenuBoundingHeight = submenu.getBoundingClientRect().height;

    if (submenuBoundingTop + submenuBoundingHeight <= 0) {
      submenuSticky.classList.add('is-visible');
    } else {
      submenuSticky.classList.remove('is-visible');
    }
  };

  window.addEventListener('load', setState);
  window.addEventListener('scroll', setState);
  window.addEventListener('resize', setState);
}
