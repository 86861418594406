/* click list
 ========================================================================== */

const setClasses = (state) => {
  const [activeItem] = [...state.filter(item => item.isVisible)];
  state.forEach((item) => { item.link.removeAttribute('aria-current'); });

  if (activeItem) activeItem.link.setAttribute('aria-current', 'step');
};

const setStatus = (state) => {
  const activeItems = state.filter(item => item.position <= window.innerHeight / 2).length;
  state.forEach((item) => { item.isVisible = false; });

  if (activeItems > 0) state[activeItems - 1].isVisible = true;

  setClasses(state);
};

const clickList = () => {
  const state = [];
  const links = document.querySelectorAll('.js-click-list-link');

  if (links.length > 0) {
    links.forEach((link) => {
      const section = document.getElementById(link.getAttribute('href').substr(1));

      state.push({
        link,
        section,
        position: Math.trunc(section.getBoundingClientRect().top),
        isVisible: false,
      });
    });

    setStatus(state);

    window.addEventListener('scroll', () => {
      state.forEach((item) => {
        item.position = Math.trunc(item.section.getBoundingClientRect().top);
      });

      setStatus(state);
    });
  }
};

export default clickList;
