/* other amount

   Will show other amount input if amount is == 0
 ========================================================================== */

export default function showOtherAmount() {
  const ecardFormComponent = document.querySelector('*[data-component="ecard-form"]');

  if (ecardFormComponent) {
    const inputs = document.querySelectorAll('.js-can-show-other-amount input[type=radio]');
    const otherAmountDiv = document.querySelector('div#ecard_amount_other');

    inputs.forEach((input) => {
      input.addEventListener('click', (event) => {
        if (event.target.value === '0' && otherAmountDiv.classList.contains('u-visually-hidden')) {
          otherAmountDiv.classList.remove('u-visually-hidden');
        } else if (!otherAmountDiv.classList.contains('u-visually-hidden')) {
          otherAmountDiv.classList.add('u-visually-hidden');
        }
      });
    });
  }
}
