/* forms
 ========================================================================== */

export default function watchFormSubmit() {
  const submitOnChangeElements = document.querySelectorAll('.js-form-autosubmit [data-submit]');
  submitOnChangeElements.forEach((element) => {
    element.addEventListener('change', (e) => {
      if (e.target.form) {
        e.target.form.submit();
      }
    });
  });
}
