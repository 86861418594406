import { updateWishlist } from './wishlist';

export const updateSizeSearchParam = (size) => {
  if (!size) return;

  window.location.hash = `size-${size}`;
};

export function updateSelectedSize(ean, size) {
  updateWishlist(ean);
  updateSizeSearchParam(size);

  // @todo : Update SiteAvailability - auto select the right size, but it seems we need to change the addSelectDropdown to
  // a real dropdown, see jonjon for that.
}
