/* mobile/check
 ========================================================================== */

/**
 * @param {int} breakpoint
 * @return {boolean}
 */
export default function isMobileDisplay(breakpoint = 767) {
  let isMobile = false;
  if (window.innerWidth <= breakpoint) {
    isMobile = true;
  }

  return isMobile;
}
