/* forms - return - timezone
 ========================================================================== */

import addDropdowns from '../../dropdowns'

export default function watchForTimezone() {
  const watchOnElementPresent = document.querySelector('#dropdown-trigger-country-back-step-5country')
  if (watchOnElementPresent) {
    const returnForm = document.querySelector('form.returnsForm')
    const countryInputs = document.querySelectorAll('[name="step5[country]"]')
    countryInputs.forEach((countryInput) => {
      countryInput.addEventListener('click', async (event) => {
        const formData = new FormData(returnForm)
        formData.set('step5[country]', event.target.value)

        fetch(`${returnForm.getAttribute('action')}?renderOnly=1`, {
          method: returnForm.getAttribute('method'),
          body: formData,
        }).then((response) =>
          response.text().then((html) => {
            const parser = new DOMParser()
            const htmlDocument = parser.parseFromString(html, 'text/html')
            let timezoneInput = htmlDocument.querySelector('#step-5-timezone')
            if (timezoneInput) {
              const appendAfterDiv = document.querySelector('#step-5-country')
              appendAfterDiv.after(timezoneInput)
              addDropdowns()
            } else {
              timezoneInput = document.querySelector('#step-5-timezone')
              if (timezoneInput) {
                timezoneInput.remove()
              }
            }
          })
        )
      })
    })
  }
}
