/* display mode
 ========================================================================== */

export default function setDisplayMode() {
  const links = document.querySelectorAll('.js-dropdown-category-submenu-display-link');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const displayModeLabel = 'displayMode';

  if (urlParams.has(displayModeLabel)) {
    const currentMode = urlParams.get(displayModeLabel);

    links.forEach((link) => {
      const mode = link.dataset.displayMode;

      if (currentMode === mode) link.classList.add('is-active');
    });
  }
}
