/* dialogs
 ========================================================================== */

import addDialog, { initDialogs } from './lib';
import addStickyDialog from './sticky';
import addRevealDialog from './reveal';
import addResponsiveDialog from './responsive';

// programmatically add dialogs
export default function addDialogs() {
  // init dialogs based on data components
  initDialogs();

  // styleguide
  addDialog('dialog-styleguide-js', {
    triggerId: 'dialog-styleguide-trigger-js',
    labelledbyId: 'dialog-styleguide-title-js',
    describedbyId: 'dialog-styleguide-desc-js',
  });

  // main navigation
  addDialog('main-navigation', {
    triggerId: 'burger-menu',
    breakpoint: 1024,
    backdrop: false,
  });

  // responsive dialog
  addResponsiveDialog('.js-dialog-login', {
    triggerId: 'dialog-trigger-login',
    labelledbyId: 'dialog-login-title',
    disableScroll: true,
    breakpoint: {
      value: 767,
      disableScroll: false,
    },
  });

  addResponsiveDialog('.js-dialog-register', {
    triggerId: 'dialog-trigger-register',
    labelledbyId: 'dialog-register-title',
    disableScroll: true,
    breakpoint: {
      value: 767,
      disableScroll: false,
    },
  });

  // sticky dialog
  addStickyDialog('.js-dialog-sticky', '.js-footer', 56);

  // reveal dialog
  addRevealDialog('.js-dialog-reveal', 3000);
}
