/* account navigation
 ========================================================================== */

export default function setAccountNavigation() {
  const sections = document.querySelectorAll('.js-account-section');
  const ratio = 1 / 3;
  const state = [];

  sections.forEach((section) => {
    state.push({
      section,
      trigger: document.querySelector(`[href*="#${section.id}"]`),
      isActive: false,
    });
  });

  const setView = (activeIndex) => {
    state.forEach((item, index) => {
      if (activeIndex === index) {
        item.section.classList.add('is-active');
        item.trigger.setAttribute('aria-current', 'step');
      } else {
        item.section.classList.remove('is-active');
        item.trigger.removeAttribute('aria-current');
      }
    });
  };

  const onScroll = () => {
    let activeIndex = 0;

    sections.forEach((section, index) => {
      if (section.getBoundingClientRect().top <= window.innerHeight * ratio) activeIndex = index;
    });

    state.forEach((item, index) => {
      activeIndex === index ? item.isActive = true : item.isActive = false;
    });

    setView(activeIndex);
  };

  onScroll();

  window.addEventListener('resize', onScroll);
  window.addEventListener('scroll', onScroll);
}
