/* Orders cancellation
 ========================================================================== */

export default function cancelOrderForm() {
  const forms = document.querySelectorAll('.cancelOrderForm');
  const cancelOrderField = document.querySelector('.orderCancelField');

  forms.forEach((form) => { // orderCancelField
    const submitAnchors = form.querySelectorAll('a[data-cancel-order]');

    submitAnchors.forEach((anchor) => { // orderCancelField
      anchor.addEventListener('click', () => {
        // eslint-disable-next-line
        if (window.confirm(form.dataset.confirmMessage) === true) {
          cancelOrderField.value = anchor.dataset.cancelOrder;
          form.submit();
        }
      });
    });
  });
}
