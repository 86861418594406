import Cookies from 'js-cookie';
import analytics from '../../analytics';

export const SUBSCRIBE_COOKIE = 'SezanePopinOptinNewsletter';

function ajax(newsletterComponent) {
  const input = newsletterComponent.querySelector('input');
  const button = newsletterComponent.querySelector('button');
  const alertDiv = newsletterComponent.parentNode.querySelector('*[role="alert"]');
  const alertDivOriginalClasses = alertDiv.getAttribute('class');
  
  newsletterComponent.addEventListener('submit', (event) => {
    event.preventDefault();
    const email = input.value;

    if (email.length > 0) {
      fetch(newsletterComponent.dataset.newsletterSubscribePath, {
        method: 'POST',
        body: JSON.stringify({ email }),
      })
        .then((response) => {
          analytics.newsletterSubscription(email);
          button.disabled = true;
          return response.json();
        })
        .then((responseJSON) => {
          // if they aren't errors we show the data value
          let errorMessage = responseJSON.data;
          // if they are errors we show the first one
          try {
            errorMessage = responseJSON.errors[0].title;

            alertDiv.setAttribute('class', `${alertDivOriginalClasses} u-color-error`);
          } catch (error) {
            alertDiv.setAttribute('class', alertDivOriginalClasses);
            Cookies.set(SUBSCRIBE_COOKIE, 1);
          }

          alertDiv.innerHTML = errorMessage;

          if (newsletterComponent.dataset.newsletterCallback === 'reload') {
            window.location.reload();
          }
        });
    }
  });
}

export default function ajaxSubscribeNewsletter() {
  const newsletterComponent = document.querySelector('*[data-component="newsletter_subscribe"]');
  if (newsletterComponent) {
    ajax(newsletterComponent);
  }
}
