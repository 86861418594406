import analytics from '../../analytics';
import { MODAL_LOAD_EVENT } from './lib';
var changeSizeChartUnits = function () {
    var radioSwitch = document.querySelector('.js-size-chart-units-switch');
    var tableCells = document.querySelectorAll('td[data-value-imp], th[data-value-imp]');
    if (!radioSwitch || tableCells.length === 0)
        return;
    var metricInput = radioSwitch.querySelector('#size-chart-switch-metric');
    var imperialInput = radioSwitch.querySelector('#size-chart-switch-imperial');
    if (!metricInput || !imperialInput)
        return;
    metricInput.addEventListener('change', function () {
        if (metricInput.checked) {
            tableCells.forEach(function (tableCell) {
                tableCell.querySelector('span').innerHTML = tableCell.dataset.value;
            });
        }
    });
    imperialInput.addEventListener('change', function () {
        if (imperialInput.checked) {
            tableCells.forEach(function (tableCell) {
                tableCell.querySelector('span').innerHTML = tableCell.dataset.valueImp;
            });
        }
    });
};
export default (function () {
    var sizesDialog = document.querySelector('#dialog-sizes');
    var sizesPage = document.querySelector('#page-sizes');
    if (sizesDialog || sizesPage) {
        changeSizeChartUnits();
        if (sizesDialog) {
            sizesDialog.addEventListener(MODAL_LOAD_EVENT, function () {
                analytics.sizeGuide(JSON.parse(document.querySelector('[data-tracking]').dataset.tracking));
            });
        }
    }
});
