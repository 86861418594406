export const handleEcardForm = () => {
  const form = document.querySelector('.ecardBalanceForm');

  if (form === null) {
    return;
  }

  form.addEventListener('submit', (event) => {
    event.preventDefault();
    event.stopPropagation();

    const mandatoryMessage = form.querySelector('.mandatoryMessage');

    if (mandatoryMessage.classList.contains('u-hidden') === false) {
      mandatoryMessage.classList.add('u-hidden');
    }

    const emailInput = form.querySelector('input[type=email]');
    const codeInput = form.querySelector('input[type=text]');

    if (!emailInput || !codeInput) {
      mandatoryMessage.classList.remove('u-hidden');
      return;
    }

    const searchParams = {
      email: emailInput.value,
      code: codeInput.value,
    };

    fetch(`${form.action}`, {
      method: 'POST',
      body: JSON.stringify(searchParams)
    })
      .then(data => data.json())
      .then(({ data, errors }) => {
        const errorEl = document.getElementById('ecard_balance_error');
        const balanceEl = document.getElementById('ecard_balance_ok');

        if (errorEl === null || balanceEl === null) {
          return;
        }

        if (errors) {
          event.target.classList.add('u-hidden');
          errorEl.classList.remove('u-hidden');
          balanceEl.classList.add('u-hidden');

          const errorTitleEl = document.querySelector('#ecard_balance_error p');

          if (errorTitleEl) {
            errorTitleEl.innerHTML = errors[0].title;
          }
        } else if (data) {
          event.target.classList.add('u-hidden');
          errorEl.classList.add('u-hidden');
          balanceEl.classList.remove('u-hidden');

          const balanceTitleEl = document.querySelector('#ecard_balance_ok p');
          if (balanceTitleEl) {
            const title = balanceTitleEl.innerHTML.replace('%balance%', `${data.balance} ${data.currency}`);
            balanceTitleEl.innerHTML = title;
          }
        }
      });
  });
};
