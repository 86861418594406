/* parallax
 ========================================================================== */

import Rellax from 'rellax';

export default function addParallax() {

  // CMS COLORAMA PARALLAX
  const coloramaParallaxSelector = '.js-colorama-parallax';

  if (document.querySelector(coloramaParallaxSelector)) {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const coloramaRellax = new Rellax(coloramaParallaxSelector, {
      breakpoints:[576, 768, 1025]
    });
  }
}
