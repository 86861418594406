/* sticky dialog
 ========================================================================== */

export default function addStickyDialog(selector, ref, offset) {
  const dialog = document.querySelector(selector);
  const reference = document.querySelector(ref);

  if (!dialog || !reference) return;

  const setState = () => {
    const viewportHeight = window.innerHeight;
    const refBoundingTop = reference.getBoundingClientRect().top;
    const refBoundingHeight = reference.getBoundingClientRect().height;

    if (viewportHeight >= refBoundingTop + offset) {
      dialog.classList.add('is-sticky');
      dialog.style.setProperty('--dialog-offset-bottom', `${refBoundingHeight / 10}rem`);
    } else {
      dialog.classList.remove('is-sticky');
      dialog.style.removeProperty('--dialog-offset-bottom');
    }
  };

  window.addEventListener('load', setState);
  window.addEventListener('scroll', setState);
  window.addEventListener('resize', setState);
}
