/* dropdowns
 ========================================================================== */

import { initDropdowns } from './lib';
import addSelectDropdowns from './select';

export default function addDropdowns() {
  // init dropdowns based on data components
  initDropdowns();
  // select dropdown
  addSelectDropdowns();
}
