/* Returns management
 ========================================================================== */

export default function returnsManagement() {
  const cancelConfirmationButtons = document.querySelectorAll(
    'button[data-component="dialog"][data-target="dialog-cancel-return"]',
  );

  cancelConfirmationButtons.forEach((cancelConfirmationButton) => {
    cancelConfirmationButton.addEventListener('click', (event) => {
      const dialog = document.getElementById('dialog-cancel-return');
      const yesLink = dialog.querySelector('a');
      yesLink.setAttribute('href', event.currentTarget.dataset.cancelReturnUrl);
    });
  });

  const container = document.querySelector('#create-return');

  if (container === null) {
    return false;
  }

  const forms = document.querySelectorAll('.returnsForm');

  forms.forEach((form) => {
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      event.stopPropagation();

      const fieldErrorContainer = form.querySelector('#form-error');
      fieldErrorContainer.innerHTML = '';

      const submitButton = form.querySelector('.returnsFormSubmitButton');
      submitButton.setAttribute('disabled', true);

      fetch(form.action, {
        method: 'POST',
        body: new FormData(form),
      })
        .then((response) => response.json())
        .then((body) => {
          if (body.status === 'error') {
            body.errorMessages.forEach((item) => {
              fieldErrorContainer.innerHTML = '';
              item.messages.forEach((message) => {
                fieldErrorContainer.innerHTML += `<div class="u-color-error">${message}</div>`;
              });
            });
          } else if (body.newTab === true) {
            window.open(body.redirect, '_blank').focus();
          } else {
            window.location = body.redirect;
          }
        })
        .finally(() => {
          submitButton.removeAttribute('disabled');
        });
    });
  });

  return true;
}
