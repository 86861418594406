import setAccountNavigation from './navigation';
import setPagination from './pagination';
import addressesManagement from './addresses';
import cancelOrderForm from './cancelOrderForm';
import removeCardForm from './removeCardForm';
import infosForm from './infosForm';
import returnsManagement from './returns';
import initWishlist from './wishlist';
import { initDropdowns } from "../dropdowns/lib";
import addDropdowns from "../dropdowns";

export default function initAccount() {
  // Set account navigation
  setAccountNavigation();

  // Set orders pagination
  const setOrdersPagination = () => {
    setPagination('body section.js-account-orders', () => {
      setAccountNavigation();
      cancelOrderForm();
      setOrdersPagination();
    });
  };
  setOrdersPagination();

  // Set user credit pagination
  const setUserCreditsPagination = () => {
    setPagination('body section.js-account-credit', () => {
      setAccountNavigation();
      cancelOrderForm();
      setUserCreditsPagination();
    });
  };
  setUserCreditsPagination();

  // Set returns pagination
  const setUserReturnsPagination = () => {
    setPagination('body section.js-account-return', () => {
      setAccountNavigation();
      cancelOrderForm();
      setUserReturnsPagination();
    });
  };
  setUserReturnsPagination();

  // Addresses management
  addressesManagement();

  // Set account cancel form
  cancelOrderForm();

  // Set account card remove
  removeCardForm();

  // Set infos form
  infosForm();

  // Set returns forms
  returnsManagement();

  initWishlist();
  initDropdowns();
  addDropdowns();
}
