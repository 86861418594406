/* product/save
 ========================================================================== */

import lastSeen from './lastSeen';

function save(productComponent) {
  if (!productComponent.dataset.productHash || !productComponent.dataset.productTemplate) {
    return;
  }

  const productData = {
    hash: productComponent.dataset.productHash,
    date: +Date.now(),
    template: productComponent.dataset.productTemplate,
  };
  let lastSeenProducts = lastSeen.get(productComponent.dataset.site);

  let currentProductPosition = false;
  lastSeenProducts.forEach((current, key) => {
    if (current.hash === productData.hash) {
      currentProductPosition = key;
    }
  });

  if (currentProductPosition === false) {
    lastSeenProducts.unshift(productData);
    if (lastSeenProducts.length > 4) {
      lastSeenProducts.pop();
    }
  } else {
    lastSeenProducts[currentProductPosition].date = +Date.now();
  }

  // Remove products seen more than 24 hours ago
  const yesterday = new Date().getTime() - 24 * 60 * 60 * 1000;
  lastSeenProducts = lastSeenProducts.filter((current) => current.date > yesterday);

  lastSeen.save(productComponent.dataset.site, lastSeenProducts);
}

export default function saveLastSeenProducts() {
  const productComponent = document.querySelector('*[data-component="product"]');

  if (productComponent) {
    save(productComponent);
  }
}
