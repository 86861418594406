var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { createRoot } from 'react-dom/client';
import analytics from '../../analytics';
import routes, { currentRoute } from '../../routes';
import Dialogs from '../dialogs/a11y-dialog-component';
import * as globalE from '../globalE';
import OnRenderWrapper from '../utils/OnRenderWrapper';
import Popins from './Popins';
export var filterPopins = function (popins) {
    var results = [];
    var globalEData = globalE.getGlobalEData();
    // Filter per page & country
    var filteredPopins = popins.filter(function (popin) {
        var checkPages = popin.pages.length > 0
            ? popin.pages.includes(window.location.pathname) || popin.pages.includes(currentRoute)
            : true;
        var checkCountries = popin.countries.length > 0 ? !!globalEData && popin.countries.includes(globalEData.countryISO) : true;
        var checkRegions = !!popin.regions && popin.regions.length > 0
            ? !!(globalEData === null || globalEData === void 0 ? void 0 : globalEData.regionCode) &&
                popin.regions.find(function (region) {
                    return region.countryCode === globalEData.countryISO && region.regions.includes(globalEData.regionCode);
                })
            : true;
        return checkPages && checkCountries && checkRegions;
    });
    // Format
    var classics = filteredPopins.filter(function (popin) { return popin.format === 'popin'; });
    var classic = classics.find(function (popin) { return popin.countries.length === 0; }) || classics[0];
    var banner = filteredPopins.find(function (popin) { return popin.format === 'banner'; });
    var centered = filteredPopins.find(function (popin) { return popin.format === 'centered'; });
    var edging = filteredPopins.find(function (popin) { return popin.format === 'edging'; });
    if (classic) {
        results.push(classic);
    }
    if (banner) {
        results.push(banner);
    }
    if (centered) {
        results.push(centered);
    }
    if (edging) {
        results.push(edging);
    }
    return results;
};
var onPopinsRendered = function () {
    var containerPopins = document.getElementById('popins');
    // popin dialog
    var dialogPopinId = 'dialog-popin';
    var dialog = document.getElementById(dialogPopinId);
    if (dialog && dialog.dataset) {
        var onlyOnce = dialog.dataset.onlyOnce;
        if (onlyOnce === '0' ||
            (onlyOnce === '1' && window.localStorage.getItem("popin-".concat(dialog.dataset.popinId)) === null)) {
            if (onlyOnce === '1') {
                window.localStorage.setItem("popin-".concat(dialog.dataset.popinId), '1');
            }
            Dialogs.create(dialogPopinId, {
                labelledbyId: 'dialog-popin-title',
                describedbyId: 'dialog-popin-desc',
                show: true,
                modal: false,
                backdrop: false,
                disableScroll: false,
            });
            analytics.dynamicPopin('Popin classic');
        }
    }
    // popin dialog centered
    var dialogCenteredId = 'dialog-centered';
    var dialogCentered = document.getElementById(dialogCenteredId);
    if (dialogCentered && dialogCentered.dataset) {
        var onlyOnce = dialogCentered.dataset.onlyOnce;
        if (onlyOnce === '0' ||
            (onlyOnce === '1' && window.localStorage.getItem("popin-".concat(dialogCentered.dataset.popinId)) === null)) {
            if (onlyOnce === '1') {
                window.localStorage.setItem("popin-".concat(dialogCentered.dataset.popinId), '1');
            }
            Dialogs.create(dialogCenteredId, {
                labelledbyId: 'dialog-centered-title',
                describedbyId: 'dialog-centered-desc',
                show: true,
                onOpen: function () {
                    // centered modal with backdrop
                    containerPopins.classList.add('is-modal-backdrop-show');
                },
                onClose: function () {
                    containerPopins.classList.remove('is-modal-backdrop-show');
                },
            });
            analytics.dynamicPopin('Popin centered');
        }
    }
    // popin dialog edging
    var dialogEdgingId = 'dialog-edging';
    var dialogEdging = document.getElementById(dialogEdgingId);
    if (dialogEdging && dialogEdging.dataset) {
        var onlyOnce = dialogEdging.dataset.onlyOnce;
        if (onlyOnce === '0' ||
            (onlyOnce === '1' && window.localStorage.getItem("popin-".concat(dialogEdging.dataset.popinId)) === null)) {
            if (onlyOnce === '1') {
                window.localStorage.setItem("popin-".concat(dialogEdging.dataset.popinId), '1');
            }
            Dialogs.create(dialogEdgingId, {
                labelledbyId: 'dialog-edging-title',
                describedbyId: 'dialog-edging-desc',
                show: true,
                onOpen: function () {
                    // centered modal with backdrop
                    containerPopins.classList.add('is-modal-backdrop-show');
                },
                onClose: function () {
                    containerPopins.classList.remove('is-modal-backdrop-show');
                },
            });
            analytics.dynamicPopin('Popin edging');
        }
    }
    // banner dialog
    var dialogBannerId = 'dialog-banner';
    var dialogBanner = document.getElementById(dialogBannerId);
    if (dialogBanner && dialogBanner.dataset) {
        var onlyOnce = dialogBanner.dataset.onlyOnce;
        if (onlyOnce === '0' ||
            (onlyOnce === '1' && window.localStorage.getItem("banner-".concat(dialogBanner.dataset.popinId)) === null)) {
            if (onlyOnce === '1') {
                window.localStorage.setItem("banner-".concat(dialogBanner.dataset.popinId), '1');
            }
            Dialogs.create(dialogBannerId, {
                labelledbyId: 'dialog-banner-title',
                describedbyId: 'dialog-banner-desc',
                show: true,
                modal: false,
                backdrop: false,
                disableScroll: false,
                onOpen: function () {
                    containerPopins.classList.add('is-banner-show');
                },
                onClose: function () {
                    containerPopins.classList.remove('is-banner-show');
                },
            });
            analytics.dynamicPopin('Popin banner');
        }
    }
    // Jackpot october dialog
    var dialogJackpotOctobreId = 'dialog-jackpot-octobre';
    var dialogJackpotOctobre = document.getElementById(dialogJackpotOctobreId);
    if (dialogJackpotOctobre && dialogJackpotOctobre.dataset) {
        var onlyOnce = dialogJackpotOctobre.dataset.onlyOnce;
        if (onlyOnce === '0' ||
            (onlyOnce === '1' && window.localStorage.getItem("popin-".concat(dialogJackpotOctobre.dataset.popinId)) === null)) {
            if (onlyOnce === '1') {
                window.localStorage.setItem("popin-".concat(dialogJackpotOctobre.dataset.popinId), '1');
            }
            Dialogs.create(dialogJackpotOctobreId, {
                describedbyId: 'dialog-jackpot-octobre-desc',
                show: true,
                onOpen: function () {
                    containerPopins.classList.add('is-dialog-centered-show');
                },
                onClose: function () {
                    containerPopins.classList.remove('is-dialog-centered-show');
                },
            });
            analytics.dynamicPopin('Popin Jackpot Octobre');
        }
    }
};
export default (function () { return __awaiter(void 0, void 0, void 0, function () {
    var popinsResponse, popins, popinsRoot;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch(routes.popins).then(function (response) { return response.json(); })];
            case 1:
                popinsResponse = _a.sent();
                popins = filterPopins(popinsResponse || []);
                popinsRoot = createRoot(document.getElementById('popins'));
                popinsRoot.render(React.createElement(OnRenderWrapper, { onRenderCallback: onPopinsRendered },
                    React.createElement(Popins, { popins: popins })));
                return [2 /*return*/];
        }
    });
}); });
