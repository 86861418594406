/* lib dropdowns
 ========================================================================== */
// vendors
// @ts-ignore
import Dropdowns from 'a11y-dropdown-component';
// init dropdowns based on data components
export function initDropdowns() {
    Dropdowns.init();
}
export default function addDropdown(id, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.breakpoint, breakpoint = _c === void 0 ? null : _c, _d = _b.breakpointPlus, breakpointPlus = _d === void 0 ? false : _d, _e = _b.hover, hover = _e === void 0 ? false : _e, _f = _b.autoClose, autoClose = _f === void 0 ? false : _f;
    var dropdown = document.getElementById(id);
    var isCreated = false;
    if (!dropdown)
        return;
    var createDropdown = function () {
        Dropdowns.render(id, {
            hover: hover,
            autoClose: autoClose,
        });
    };
    var destroyDropdown = function () {
        Dropdowns.destroy(id);
    };
    var setDropdownAbove = function () {
        if (window.innerWidth > breakpoint && !isCreated) {
            isCreated = true;
            createDropdown();
        }
        else if (window.innerWidth <= breakpoint && isCreated) {
            isCreated = false;
            destroyDropdown();
        }
    };
    var setDropdownBelow = function () {
        if (window.innerWidth <= breakpoint && !isCreated) {
            isCreated = true;
            createDropdown();
        }
        else if (window.innerWidth > breakpoint && isCreated) {
            isCreated = false;
            destroyDropdown();
        }
    };
    var onLoad = function () {
        breakpointPlus ? setDropdownAbove() : setDropdownBelow();
    };
    var onResize = function () {
        breakpointPlus ? setDropdownAbove() : setDropdownBelow();
    };
    if (breakpoint) {
        window.addEventListener('load', onLoad);
        window.addEventListener('resize', onResize);
    }
    else {
        createDropdown();
    }
}
