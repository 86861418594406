/* sticky product bar
 ========================================================================== */

import { updateSelectedSize } from './sizeSelection';

export default function addStickyProductBar() {
  const stickyProductBar = document.querySelector('.js-sticky-bar');
  const stickyTrigger = document.querySelector('.js-sticky-trigger');

  if (!stickyProductBar || !stickyTrigger) return;

  const setVisibility = (disableAnimation = false) => () => {
    const boundingTop = stickyTrigger.getBoundingClientRect().top;
    const boundingHeight = stickyTrigger.getBoundingClientRect().height;

    if (boundingTop + boundingHeight <= 0) {
      stickyProductBar.classList.add('is-visible');
      if (disableAnimation) {
        stickyProductBar.classList.add('no-animate');
      }
    } else {
      stickyProductBar.classList.remove('is-visible');
      if (disableAnimation) {
        stickyProductBar.classList.remove('no-animate');
      }
    }
  };

  window.addEventListener('load', setVisibility(true));
  window.addEventListener('scroll', setVisibility());
  window.addEventListener('resize', setVisibility());
  setVisibility(true)();

  const barSizeLinks = stickyProductBar.querySelectorAll('a[data-size-change-ean]');
  const sizeCheckboxes = document.querySelectorAll('input[data-size]');
  const barSizeHolder = stickyProductBar.querySelector('.sticky-switch-size-holder');

  const updateSizeDropdown = size => {
    barSizeHolder.textContent = size;
    barSizeLinks.forEach(link => {
      if (link.dataset.sizeChangeSize === size) {
        link.classList.add('is-active');
      } else {
        link.classList.remove('is-active');
      }
    })
  }

  const changeSizeFromBar = (e) => {
    e.preventDefault();

    barSizeLinks.forEach((barSizeLink) => {
      barSizeLink.classList.remove('is-active');
    });

    e.target.classList.add('is-active');
    sizeCheckboxes.forEach((i) => { i.checked = i.value === e.target.dataset.sizeChangeEan; });
    updateSelectedSize(e.target.dataset.sizeChangeEan, e.target.dataset.sizeChangeSize);
  };

  const changeSizeFromCheckbox = (e) => {
    updateSizeDropdown(e.target.dataset.size);
    updateSelectedSize(e.target.value, e.target.dataset.size);
  };

  if (barSizeLinks.length && sizeCheckboxes.length && barSizeHolder) {
    barSizeLinks.forEach(link => link.addEventListener('click', changeSizeFromBar));
    sizeCheckboxes.forEach(checkbox => checkbox.addEventListener('click', changeSizeFromCheckbox));
  }

  const selectedSize = decodeURI(document.location.hash.replace('#size-', ''));
  const selectedSizeInput =
    document.querySelector(`input[data-size="${selectedSize}"]`)
    || document.querySelector(`input[data-size][data-item-available="1"]`)
    || document.querySelector(`input[data-size]`)
  ;

  if (selectedSizeInput) {
    document.querySelectorAll(`input[data-size="${selectedSizeInput.dataset.size}"]`).forEach(input => {input.checked = true});
    updateSizeDropdown(selectedSizeInput.dataset.size);
    updateSelectedSize(selectedSizeInput.value, selectedSizeInput.dataset.size);
  }
}
