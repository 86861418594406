/* responsive dialog
 ========================================================================== */

// vendors
import Dialogs from './a11y-dialog-component';

// responsive dialog
export default function addResponsiveDialog(selector, {
  triggerId = null,
  labelledbyId = null,
  describedbyId = null,
  show = false,
  modal = true,
  tooltip = false,
  backdrop = true,
  disableScroll = true,
  disableIosScroll = false,
  transitionDuration = 200,
  breakpoint = {},
} = {}) {
  const dialogs = document.querySelectorAll(selector);

  dialogs.forEach((dialog) => {
    let dialogDesktopIsCreated = false;
    let dialogMobileIsCreated = false;

    const setDialogs = () => {
      if (window.matchMedia(`(max-width: ${breakpoint.value}px)`).matches && dialogDesktopIsCreated) {
        dialogDesktopIsCreated = false;
        Dialogs.destroy(dialog.id);
      }

      if (window.matchMedia(`(min-width: ${breakpoint.value + 1}px)`).matches && dialogMobileIsCreated) {
        dialogMobileIsCreated = false;
        Dialogs.destroy(dialog.id);
      }

      if (window.matchMedia(`(max-width: ${breakpoint.value}px)`).matches && !dialogMobileIsCreated) {
        dialogMobileIsCreated = true;
        Dialogs.create(dialog.id, {
          triggerId,
          labelledbyId,
          describedbyId,
          show: breakpoint.show,
          modal: breakpoint.modal,
          tooltip: breakpoint.tooltip,
          backdrop: breakpoint.backdrop,
          disableScroll: breakpoint.disableScroll,
          disableIosScroll: breakpoint.disableIosScroll,
          transitionDuration: breakpoint.transitionDuration,
        });
      }

      if (window.matchMedia(`(min-width: ${breakpoint.value + 1}px)`).matches && !dialogDesktopIsCreated) {
        dialogDesktopIsCreated = true;
        Dialogs.create(dialog.id, {
          triggerId,
          labelledbyId,
          describedbyId,
          show,
          modal,
          tooltip,
          backdrop,
          disableScroll,
          disableIosScroll,
          transitionDuration,
        });
      }
    };

    const onResize = () => {
      setDialogs();
    };

    const onLoad = () => {
      setDialogs();
    };

    window.addEventListener('resize', onResize);
    window.addEventListener('load', onLoad);
  });
}
