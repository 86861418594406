/**
 * Redirect method to be able to stub the redirect in end to end tests
 *
 * @param {string} url
 */
export var redirect = function (url) {
    window.location.href = url;
};
window.redirect = redirect;
export function hideOnClickOutside(element) {
    var outsideClickListener = function (event) {
        if (!element.contains(event.target)) {
            element.classList.add('u-hidden');
        }
    };
    document.addEventListener('click', outsideClickListener);
    document.addEventListener('keyup', function (event) {
        if (event.key === 'Escape') {
            element.classList.add('u-hidden');
        }
    });
}
export var isWebview = function () {
    return navigator.userAgent.startsWith('app-');
};
export var getSite = function () {
    return window.location.pathname.split('/')[1];
};
// From https://stackoverflow.com/a/47614491
export var setHTMLWithScripts = function (elm, html, mode, selector) {
    if (mode === void 0) { mode = 'inner'; }
    if (mode === 'outer' && !selector) {
        throw new Error('Selector is required when using outer mode');
    }
    if (mode === 'outer') {
        elm.outerHTML = html;
    }
    else {
        elm.innerHTML = html;
    }
    // Needs to re-query element since the reference might have changed in case of outerHTML
    var replacedElm = mode === 'outer' ? document.querySelector(selector) : elm;
    Array.from(replacedElm.querySelectorAll('script')).forEach(function (oldScript) {
        eval(oldScript.innerHTML);
    });
};
