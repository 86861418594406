import { MODAL_LOAD_EVENT } from '../dialogs/lib';
import * as addSlider from '../sliders/sliders';
import { initDropdowns } from '../dropdowns/lib';
import addSelectDropdowns from '../dropdowns/select';
import { renderProductCta } from '../product/cta/ProductCta';
import { PRODUCT_STATE_CHANGE, VARIANT_SWITCH } from '../../events';
import analytics from '../../analytics';
import routes from '../../routes';
var product = window.product;
function toggleShopTheLookButton(button, variantId) {
    var variant = product.variants.find(function (productVariant) { return productVariant.id === variantId; });
    if (variant.mediaVariants) {
        var params_1 = new URLSearchParams();
        variant.mediaVariants.forEach(function (mediaVariant) { return params_1.append('codes[]', mediaVariant); });
        button.dataset.modalUrl = "".concat(routes.shop_the_look, "?").concat(params_1.toString());
        button.classList.remove('u-hidden');
    }
    else {
        button.classList.add('u-hidden');
    }
}
export default function () {
    // Button, since we don't replace the slider on variant switch, we need to hide / show the button according to variant state
    var button = document.querySelector('button[data-role="shop-the-look"]');
    if (button) {
        toggleShopTheLookButton(button, product.selectedVariant.id);
        document.addEventListener(VARIANT_SWITCH, function (e) {
            toggleShopTheLookButton(button, Number(e.detail.variantId));
        });
    }
    // Dialog
    var dialog = document.querySelector('#ajax-modal2');
    if (dialog) {
        dialog.addEventListener(MODAL_LOAD_EVENT, function (e) {
            if (e.detail.role === 'shop-the-look') {
                // init modal slider
                addSlider.shopTheLook();
                // init modal dropdowns
                initDropdowns();
                addSelectDropdowns(dialog);
                // display CTA buttons
                renderProductCta({ container: dialog, inDialogContext: true });
                // Update CTA on ean change
                dialog.querySelectorAll('input[name="ean[]"]').forEach(function (input) {
                    input.addEventListener('change', function (changeEvent) {
                        document.dispatchEvent(new CustomEvent(PRODUCT_STATE_CHANGE, {
                            detail: {
                                productHash: changeEvent.target.dataset.productHash,
                                currentItemHash: changeEvent.target.dataset.itemHash,
                            },
                        }));
                    });
                });
                analytics.shopTheLook(JSON.parse(document.querySelector('[data-tracking]').dataset.tracking));
                dialog.querySelectorAll('[data-product-cta]').forEach(function (button) {
                    button.addEventListener('click', function () {
                        analytics.shopTheLookProductClicked(JSON.parse(button.dataset.product).selectedVariant.tracking);
                    });
                });
            }
        });
    }
}
