import React from 'react';
import PopinBanner from './PopinBanner';
import PopinCentered from './PopinCentered';
import PopinClassic from './PopinClassic';
import PopinEdging from './PopinEdging';
import PopinJackpotOctobre from './PopinJackpotOctobre';
var Popins = function (_a) {
    var popins = _a.popins;
    return (React.createElement(React.Fragment, null,
        popins.map(function (popin, index) {
            if (popin.format === 'banner') {
                return React.createElement(PopinBanner, { key: index, popin: popin });
            }
            if (popin.format === 'popin') {
                return React.createElement(PopinClassic, { key: index, popin: popin });
            }
            if (popin.format === 'centered') {
                return React.createElement(PopinCentered, { key: index, popin: popin });
            }
            if (popin.format === 'edging') {
                return React.createElement(PopinEdging, { key: index, popin: popin });
            }
            return null;
        }),
        React.createElement(PopinJackpotOctobre, null)));
};
export default Popins;
