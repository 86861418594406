/* off canvas
 ========================================================================== */
var triggerOpenBtn = null;
function showContent(trigger) {
    triggerOpenBtn = trigger;
    var content = document.getElementById(trigger.getAttribute('aria-controls'));
    content.classList.add('is-opened');
    trigger.setAttribute('aria-expanded', 'true');
    var focusableEls = content.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
    var firstFocusableEl = focusableEls[0];
    firstFocusableEl.focus();
}
function hideContent(trigger, el) {
    var contentId = '';
    if (trigger.dataset.offcanvasTarget === 'open') {
        contentId = trigger.getAttribute('aria-controls');
        trigger.setAttribute('aria-expanded', 'false');
        trigger.focus();
    }
    else {
        contentId = trigger.dataset.controls;
    }
    var content = document.getElementById(contentId);
    content.classList.remove('is-opened');
    triggerOpenBtn.setAttribute('aria-expanded', 'false');
    triggerOpenBtn.focus();
}
function onKeydown(event, closeBtn, el) {
    if (event.key === 'Tab' || event.keyCode === 9) {
        var content = document.getElementById(closeBtn.dataset.controls);
        trapFocus(event, content);
    }
    else if (event.key === 'Escape' || event.keyCode === 27) {
        hideContent(closeBtn, el);
    }
}
function trapFocus(event, content) {
    var focusableEls = content.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
    var firstFocusableEl = focusableEls[0];
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    if (content.classList.contains('is-opened')) {
        if (event.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                event.preventDefault();
            }
        }
        else {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                event.preventDefault();
            }
        }
    }
}
export default function addOffCanvas() {
    var offcanvas = document.querySelectorAll('[data-component="offcanvas"]');
    offcanvas.forEach(function (el) {
        var openBtns = el.querySelectorAll('[data-offcanvas-target="open"]');
        openBtns.forEach(function (openBtn) {
            openBtn.addEventListener('click', function () {
                if (openBtn.getAttribute('aria-expanded') === 'false') {
                    showContent(openBtn);
                }
                else {
                    hideContent(openBtn, el);
                }
            });
        });
        var closeBtn = el.querySelector('[data-offcanvas-target="close"]');
        closeBtn === null || closeBtn === void 0 ? void 0 : closeBtn.addEventListener('click', function () {
            hideContent(closeBtn, el);
        });
        el.addEventListener('keydown', function (event) {
            onKeydown(event, closeBtn, el);
        });
    });
}
