/* Personal information form
 ========================================================================== */
import setAccountNavigation from './navigation';
import { initDropdowns } from '../dropdowns/lib';
import addDropdowns from '../dropdowns';
export default function infosForm() {
    var forms = document.querySelectorAll('body .infosForm');
    var container = document.querySelector('#mes-infos');
    var value;
    var message;
    forms.forEach(function (form) {
        // For browsers not supporting submitter property in the submit event (Safari < 15)
        var buttons = form.querySelectorAll('button[type="submit"]');
        buttons.forEach(function (button) {
            button.addEventListener('click', function () {
                value = button.value;
                message = button.dataset.message;
            });
            button.addEventListener('keydown', function (event) {
                if (event.key === 'Enter') {
                    value = button.value;
                    message = button.dataset.message;
                }
            });
        });
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            event.stopPropagation();
            var action = event.submitter ? event.submitter.name : value;
            if (action === 'update') {
                fetch(form.action, {
                    method: 'POST',
                    body: new FormData(form),
                })
                    .then(function (response) { return response.text(); })
                    .then(function (body) {
                    container.outerHTML = body;
                    setAccountNavigation();
                    infosForm();
                    initDropdowns();
                    addDropdowns();
                });
            }
            else if (action === 'delete') {
                if (window.confirm(event.submitter ? event.submitter.dataset.message : message) === true) {
                    var originalFormData = new FormData(form);
                    var deleteFormData = new FormData();
                    deleteFormData.append('delete_token', originalFormData.get('delete_token'));
                    fetch(form.dataset.deleteAction, {
                        method: 'POST',
                        redirect: 'follow',
                        body: deleteFormData,
                    })
                        .then(function (response) {
                        if (response.redirected) {
                            window.location.href = response.url;
                        }
                        return response.text();
                    })
                        .then(function (body) {
                        container.outerHTML = body;
                        setAccountNavigation();
                        infosForm();
                        initDropdowns();
                        addDropdowns();
                    });
                }
            }
        });
    });
}
