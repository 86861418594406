/* cart/handleError
 ========================================================================== */

import Dialogs from '../dialogs/a11y-dialog-component';

const DIALOG_CART_FAILURE = 'dialog-cart-failure';

export default function handleError(cart) {
  const errorMessage = document.querySelector('#dialog-cart-failure-message');
  if (errorMessage && cart.dataset.error !== 'unknown_error') {
    errorMessage.innerHTML = cart.dataset.error;
  }

  Dialogs.create(DIALOG_CART_FAILURE);
  setTimeout(() => {
    Dialogs.show(DIALOG_CART_FAILURE);
  }, 0);
}
