/* default config sliders
 ========================================================================== */
import translate from '../../translate';

// default config
export default {
  containerModifierClass: 'c-slider__container--',
  wrapperClass: 'c-slider__list',
  slideClass: 'c-slider__item',
  slideActiveClass: 'is-active',
  slideVisibleClass: 'is-visible',
  slidePrevClass: 'c-slider__item--prev',
  slideNextClass: 'c-slider__item--next',
  keyboard: true,
  a11y: {
    prevSlideMessage: translate('front.slider.prev_slide'),
    nextSlideMessage: translate('front.slider.next_slide'),
    firstSlideMessage: translate('front.slider.first_slide'),
    lastSlideMessage: translate('front.slider.last_slide'),
  },
  fadeEffect: {
    crossFade: true,
  },
  lazy: true,
};

// default navigation config
export const navigationConfig = {
  disabledClass: 'is-disabled',
  hiddenClass: '',
};

// default pagination config
export const paginationConfig = {
  clickable: true,
  bulletClass: 'c-slider__bullet',
  bulletActiveClass: 'is-active',
  renderBullet: (index, className) =>
    `<li class=${className}><span class="c-slider__bullet-label">${index + 1}</span></li>`,
};
