var _this = this;
// Custom event used on slider change (ie for variant reload)
export var SLIDE_CHANGE = 'slide_change';
export var PRODUCT_STATE_CHANGE = 'product_state_change';
export var ADD_TO_CART = 'add_to_cart';
export var IS_ADDING_TO_CART = 'is_adding_to_cart';
export var VARIANT_SWITCH = 'variant_switch';
// From http://youmightnotneedjquery.com/#delegate
export var on = function (selector, eventName, handler) {
    document.addEventListener(eventName, function (e) {
        // loop parent nodes from the target to the delegation node
        for (var target = e.target; target && target !== _this; target = target.parentNode) {
            if (target.matches && target.matches(selector)) {
                handler(target, e);
                break;
            }
        }
    }, false);
};
