/* cart/retrieveFormData
 ========================================================================== */

import isMobileDisplay from '../mobile/check';

export default function retrieveFormData(form, removeCustomisation = false) {
  const formData = new FormData(form);
  const eanList = formData.getAll(`ean[${isMobileDisplay() ? 'mobile' : 'desktop'}][]`);

  eanList.forEach(ean => formData.append('ean[]', ean))
  formData.delete('ean[desktop][]');
  formData.delete('ean[mobile][]');

  if (removeCustomisation) {
    formData.delete('customizations[]');
  }

  return formData;
}
