import React from 'react';
import translate from '../../../../translate';
var LastMinuteShoppingButton = function (_a) {
    var tracking = _a.tracking, className = _a.className, isLoading = _a.isLoading, id = _a.id;
    return (React.createElement("button", { "data-cart-add-product": true, "data-tracking": "".concat(JSON.stringify(tracking)), "data-last-minute": "true", type: "button", className: "c-btn c-btn--loader u-w-100 js-add-cart-button".concat(className ? " ".concat(className) : ''), id: id },
        React.createElement("span", { className: isLoading ? 'u-visibility-hidden' : '' }, translate('front.product.index.paris_fast_delivery')),
        isLoading && (React.createElement("div", { className: "c-loader c-loader--button c-loader--fullscreen" },
            React.createElement("div", { className: "c-loader__spinner" })))));
};
export default LastMinuteShoppingButton;
