/* quickshop/ajax
   ========================================================================== */
import updateMobileCartCounter from '../cart/counter';
import handleError from '../cart/handleError';
import setCartVisibility from '../cart/setCartVisibility';
import retrieveFormData from '../cart/retrieveFormData';
import showLoader from '../loader';
export default function ajaxAddProduct(quickshopComponent) {
    var buttons = quickshopComponent.querySelectorAll('[data-quickshop-add-product-btn]');
    if (buttons.length > 0) {
        buttons.forEach(function (button) {
            button.addEventListener('click', function () {
                var form = button.closest('[data-quickshop-add-product-form]');
                button.disabled = true;
                var hideLoader = showLoader(button, { hideContent: true });
                fetch(form.action, {
                    method: 'POST',
                    body: retrieveFormData(form),
                })
                    .then(function (response) {
                    if (response.redirected) {
                        window.location.href = response.url;
                    }
                    return response.text();
                })
                    .then(function (body) {
                    // @ts-ignore
                    if (quickshopComponent.dialog) {
                        // @ts-ignore
                        quickshopComponent.dialog.hide();
                    }
                    var lists = document.querySelectorAll('li.js-dynamic-menu');
                    lists.forEach(function (list) {
                        list.outerHTML = body;
                    });
                    var cart = document.querySelector('.js-cart');
                    if ('error' in cart.dataset) {
                        handleError(cart);
                        return;
                    }
                    setCartVisibility(cart);
                    updateMobileCartCounter();
                })
                    .finally(function () {
                    hideLoader();
                    button.disabled = false;
                });
            });
        });
    }
}
