/* lib accordions
 ========================================================================== */

// vendors
import Accordions from 'a11y-accordion-component';

// init accordions based on data components
export function initAccordions() {
  Accordions.init();
}

// set auto heights
export function setAutoHeights() {
  const accordions = document.querySelectorAll('[data-auto-height="true"]');

  const setHeight = (panels) => {
    panels.forEach((panel) => {
      const nestedPanels = [...panel.querySelectorAll('[data-controls]')].map(trigger => document.getElementById(trigger.dataset.controls));
      let nestedPanelHeights = 0;

      nestedPanels.forEach((nestedPanel) => { nestedPanelHeights += nestedPanel.scrollHeight; });

      panel.style.setProperty('--accordion-panel-max-height', `${(panel.scrollHeight + nestedPanelHeights) / 10}rem`);
    });
  };

  accordions.forEach((accordion) => {
    const panels = [...accordion.querySelectorAll('[data-controls]')].map(trigger => document.getElementById(trigger.dataset.controls));

    setHeight(panels);

    window.addEventListener('resize', setHeight.bind(this, panels));
  });
}

// add accordion
export default function addAccordion(id, {
  breakpoint = null,
  breakpointPlus = false,
  isMultiSelectable = true,
  isCollapsible = true,
} = {}) {
  const accordion = document.getElementById(id);
  let isCreated = false;

  if (!accordion) return;

  const createAccordion = () => {
    Accordions.render(id, {
      isMultiSelectable,
      isCollapsible,
    });
  };

  const destroyAccordion = () => {
    Accordions.destroy(id);
  };

  const setAccordionAbove = () => {
    if (window.innerWidth > breakpoint && !isCreated) {
      isCreated = true;
      createAccordion();
    } else if (window.innerWidth <= breakpoint && isCreated) {
      isCreated = false;
      destroyAccordion();
    }
  };

  const setAccordionBelow = () => {
    if (window.innerWidth <= breakpoint && !isCreated) {
      isCreated = true;
      createAccordion();
    } else if (window.innerWidth > breakpoint && isCreated) {
      isCreated = false;
      destroyAccordion();
    }
  };

  const onLoad = () => {
    breakpointPlus ? setAccordionAbove() : setAccordionBelow();
  };

  const onResize = () => {
    breakpointPlus ? setAccordionAbove() : setAccordionBelow();
  };

  if (breakpoint) {
    window.addEventListener('load', onLoad);
    window.addEventListener('resize', onResize);
  } else {
    createAccordion();
  }
}
