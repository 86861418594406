import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import AddAlertButton from '../product/cta/buttons/AddAlertButton';
import AddToBasketButton from '../product/cta/buttons/AddToBasketButton';
import AvailableSoonButton from '../product/cta/buttons/AvailableSoonButton';
export var MultiProductCta = function (_a) {
    var selectedVariant1 = _a.selectedVariant1, selectedVariant2 = _a.selectedVariant2, selectedItem1 = _a.selectedItem1, selectedItem2 = _a.selectedItem2;
    var idButton = useState(uuidv4())[0];
    var isAvailable = selectedItem1.available && selectedItem2.available;
    var isPreorder = selectedVariant1.preorder || selectedVariant2.preorder;
    var isAvailableSoon = selectedVariant1.availableSoon || selectedVariant2.availableSoon;
    var isInOtherBasket = selectedItem1.inOtherBasket || selectedItem2.inOtherBasket;
    var mainCta = React.createElement(AddAlertButton, { soon: isAvailableSoon });
    if (isAvailableSoon) {
        mainCta = React.createElement(AvailableSoonButton, null);
    }
    else if (isAvailable || isInOtherBasket) {
        mainCta = (React.createElement(AddToBasketButton, { isAvailable: isAvailable, isPreorder: isPreorder, isInOtherBasket: isInOtherBasket, preorderDate: selectedVariant1.preorderDate || selectedVariant2.preorderDate, addedToCart: false, isLoading: false, id: idButton }));
    }
    return mainCta;
};
