var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import translate from '../../translate';
import { getCountryName, getBrandCountries, getDefaultCountry, getMapCountrySite } from '../../countries';
import { getGlobalEData, handleGlobalEBanner } from '../globalE';
var FORCED_CURRENCY_SYMBOLS = {
    AUD: 'AU$',
    CAD: 'CA$',
    HKD: 'HK$',
    NZD: 'NZ$',
    SGD: 'SG$',
    TWD: 'NT$',
    USD: 'US$',
    XCD: 'EC$',
};
export default (function () { return __awaiter(void 0, void 0, void 0, function () {
    var switchers, globalEData, defaultCountry, countries, countryData, mapCountrySite, countrySite, countryFromSite, globalECountries, countryCode, currencySymbol, currencyCode, forcedCurrencySymbol, formatter, formattedNb;
    var _a;
    return __generator(this, function (_b) {
        switchers = document.querySelectorAll('.c-header [data-switch-redirect]');
        if (!switchers.length)
            return [2 /*return*/];
        globalEData = getGlobalEData();
        defaultCountry = getDefaultCountry();
        if (!globalEData || !defaultCountry)
            return [2 /*return*/];
        countries = getBrandCountries();
        countryData = countries.find(function (c) { return c.label === globalEData.countryISO; });
        mapCountrySite = getMapCountrySite();
        countrySite = mapCountrySite[globalEData.countryISO];
        countryFromSite = countryData && countrySite && window.location.pathname.startsWith("/".concat(countrySite));
        globalECountries = window.globalECountries || [];
        if (countryFromSite) {
            countryCode = countryData.label;
            currencyCode = globalECountries.includes(countryData.label)
                ? globalEData.currencyCode
                : countryData.currencyCode;
            forcedCurrencySymbol = FORCED_CURRENCY_SYMBOLS[currencyCode];
            if (forcedCurrencySymbol) {
                currencySymbol = forcedCurrencySymbol;
            }
            else {
                try {
                    formatter = new Intl.NumberFormat(document.documentElement.lang, {
                        style: 'currency',
                        currency: currencyCode,
                    });
                    formattedNb = formatter.formatToParts(0);
                    currencySymbol = (_a = formattedNb.find(function (part) { return part.type === 'currency'; })) === null || _a === void 0 ? void 0 : _a.value;
                }
                catch (e) {
                    currencySymbol = currencyCode;
                }
            }
        }
        else {
            countryCode = defaultCountry.code;
            currencySymbol = FORCED_CURRENCY_SYMBOLS[defaultCountry.currencyCode] || defaultCountry.currencyLabel;
        }
        switchers.forEach(function (switcher) { return __awaiter(void 0, void 0, void 0, function () {
            var countryNameEl, currencyEl, countryName, countryNameMobileEl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        countryNameEl = switcher.querySelector('[data-country-name]');
                        if (countryNameEl) {
                            countryNameEl.innerHTML = countryCode;
                        }
                        currencyEl = switcher.querySelector('[data-currency]');
                        if (currencyEl) {
                            currencyEl.innerHTML = "(".concat(currencySymbol, ")");
                        }
                        // Switch action
                        switcher.addEventListener('click', function (e) {
                            e.stopPropagation();
                            handleGlobalEBanner(true);
                        });
                        return [4 /*yield*/, getCountryName(countryCode)];
                    case 1:
                        countryName = _a.sent();
                        countryNameMobileEl = switcher.querySelector('[data-country-name-mobile]');
                        if (countryNameMobileEl) {
                            countryNameMobileEl.innerHTML = translate('front.header.shipping_country', {
                                country: countryName,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); });
        return [2 /*return*/];
    });
}); });
