/* auto size input
 ========================================================================== */

function getCloneElement (placeholder, classNames) {
  const newElement = document.createElement('span');
  const newContent = document.createTextNode(placeholder);
  const cloneClassName = 'c-search__field--clone';

  newElement.classList.add(...classNames.split(' '), cloneClassName);
  newElement.appendChild(newContent);

  return document.body.appendChild(newElement);
};

function setInputSize (input, cloneElement) {
  const customPropertyName = '--search-width';

  input.style.setProperty(customPropertyName, `${cloneElement.scrollWidth / 10}rem`);
};

export function setInputState (input, cloneElement, placeholder) {
  cloneElement.textContent = input.value === '' ? placeholder : input.value;
  setInputSize(input, cloneElement);
};

function onBlurInput (input, cloneElement, placeholder) {
  if (input.value === '') {
    cloneElement.textContent = placeholder;
    setInputSize(input, cloneElement);
  }
};

export function autoSizeInput() {
  const inputs = document.querySelectorAll('[data-component="auto-size"]');

  inputs.forEach((input) => {
    const { placeholder, className } = input;
    const cloneElement = getCloneElement(placeholder, className);

    setInputSize(input, cloneElement);

    input.addEventListener('blur', onBlurInput.bind(this, input, cloneElement, placeholder));
    input.addEventListener('input', () => setTimeout(() => setInputState(input, cloneElement, placeholder), 0));
    window.addEventListener('resize', () => setInputSize(input, cloneElement));
  });
}
