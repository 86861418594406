/* cart/setCartVisibility
 ========================================================================== */

export default function setCartVisibility(cart) {
  if (!cart) {
    return;
  }

  const delay = 10000;

  const showCart = () => cart.classList.add('is-visible');
  const hideCart = () => cart.classList.remove('is-visible');

  window.scrollTo(0, 0);
  window.setTimeout(() => hideCart(), delay);

  showCart();

  cart.addEventListener('mouseover', hideCart);
}
