/* warning if closing

   will prompt the user before closing the current browser tab
 ========================================================================== */

export default function warningIfClosing() {
  const ecardFormComponent = document.querySelector('*[data-component="ecard-form"]');

  if (!ecardFormComponent) {
    return;
  }

  const windowBeforeUnload = (event) => {
    const blacklist = ['ecard[amountSelect]', 'ecard[amount]', 'ecard[sendDate]', 'ecard[_token]'];
    const inputs = ecardFormComponent.querySelectorAll('form input');

    inputs.forEach((input) => {
      if (blacklist.indexOf(input.name) === -1 && input.value !== '') {
        event.returnValue = 1; // will prompt user based on what the browser want to :)
      }
    });
  };

  window.addEventListener('beforeunload', windowBeforeUnload);

  ecardFormComponent.querySelector('form').addEventListener('submit', () => {
    window.removeEventListener('beforeunload', windowBeforeUnload);
  });
}
