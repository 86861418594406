import React from 'react';
import translate from '../../translate';
var PopinBanner = function (_a) {
    var _b;
    var popin = _a.popin;
    var colorPrimary = popin.colorPrimary;
    var colorSecondary = popin.colorSecondary;
    var bannerStyle = (_b = {},
        _b['--banner-background-color'] = colorPrimary,
        _b['--banner-inner-background-color'] = colorPrimary,
        _b['--banner-inner-color'] = colorSecondary,
        _b);
    return (React.createElement("div", { id: "dialog-banner", className: "c-banner", "data-popin-id": popin.uid, "data-only-once": popin.oncePerSession, style: bannerStyle },
        React.createElement("div", { className: "c-banner__inner" },
            React.createElement("div", { className: "o-container" },
                React.createElement("div", { className: "o-grid o-grid--nowrap" },
                    React.createElement("div", { className: "o-grid__col o-grid__col--offset-1 o-grid__col--22" },
                        React.createElement("div", { className: "c-banner__box u-flex u-flex-cross-center" },
                            React.createElement("button", { type: "button", "aria-label": translate('front.popin.close'), className: "c-banner__close", "data-dialog-hide": true },
                                React.createElement("i", { className: "c-icon" },
                                    React.createElement("svg", { width: "22", height: "22", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53.34 53.34", className: "c-icon__svg", "aria-hidden": "true" },
                                        React.createElement("path", { d: "M53.34 7.64L45.7 0 26.67 19.03 7.64 0 0 7.64l19.03 19.03L0 45.7l7.64 7.64 19.03-19.03L45.7 53.34l7.64-7.64-19.03-19.03L53.34 7.64z" })))),
                            React.createElement("div", { className: "s-cms-banner u-w-100" },
                                React.createElement("div", { id: "dialog-banner-desc", className: "s-wysiwyg u-line-clamp-1 u-line-clamp-3@sm u-line-clamp-4@xs", dangerouslySetInnerHTML: { __html: popin.content } })))))))));
};
export default PopinBanner;
