var routes = JSON.parse(document.querySelector('body').dataset.routes);
export var currentRoute = document.querySelector('body').dataset.route;
export var path = function (route, params) {
    var generatedPath = route;
    Object.entries(params).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        generatedPath = generatedPath.replace("__".concat(key, "__"), value.toString());
    });
    return generatedPath;
};
export default routes;
