import { SLIDE_CHANGE } from '../../events';

const changeLabelColor = (imgSrc) => {
  if (!imgSrc) return;

  const img = new Image();
  img.crossOrigin = 'Anonymous';
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const header = document.querySelector('.c-header');
  const colorClassList = {
    black: 'c-header--menu-color-black',
    white: 'c-header--menu-color-white',
  };

  img.src = imgSrc;

  img.onload = () => {
    // draw 1px image from the top of image source
    context.drawImage(img, 0, 0, img.width, 100, 0, 0, 1, 1);

    // get rgb color from 1px image
    const [r, g, b] = context.getImageData(0, 0, 1, 1).data.slice(0, 3);

    // HSP equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    const colorClass = colorClassList[hsp <= 127.5 ? 'white' : 'black'];

    header.classList.remove(...Object.values(colorClassList));
    header.classList.add(colorClass);
  };
};

export function initDiaporamaLabelColor() {
  const hasMenuLabelColorAuto = document.querySelector('.js-menu-color-auto') !== null;
  const headerCover = document.querySelector('.c-header--cover') !== null;
  const slider = document.querySelector('.c-slider.s-cms--diaporama');

  if (!hasMenuLabelColorAuto || !headerCover || !slider) {
    return;
  }

  const slides = slider.querySelectorAll('.js-slider-loop-autoplay');

  if (slides[0]) {
    let imgElt = slider.querySelector('.c-slider__item.is-active .c-media').firstElementChild;
    // Avoid an error if img or video is displayed
    // eslint-disable-next-line no-nested-ternary
    let imgSrc = imgElt.dataset.srcset ? imgElt.dataset.srcset : (imgElt.dataset.src ? imgElt.dataset.src : false);

    changeLabelColor(imgSrc);

    slides[0].addEventListener(SLIDE_CHANGE, () => {
      imgElt = slider.querySelector('.c-slider__item.is-active .c-media').firstElementChild;
      // Avoid an error if img or video is displayed
      // eslint-disable-next-line no-nested-ternary
      imgSrc = imgElt.dataset.srcset ? imgElt.dataset.srcset : (imgElt.dataset.src ? imgElt.dataset.src : false);

      changeLabelColor(imgSrc);
    });
  }
}

export function initPageLabelColor() {
  const hasMenuLabelColorAuto = document.querySelector('.js-menu-color-auto') !== null;
  const headerCover = document.querySelector('.c-header--cover') !== null;
  const container = document.querySelector('.c-section--cover.c-section--illustration');

  if (!hasMenuLabelColorAuto || !headerCover || !container) {
    return;
  }

  const imgSrc = container.dataset.imageUrl;
  changeLabelColor(imgSrc);
}
