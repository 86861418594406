/* states
 ========================================================================== */

const add = 'add';
const remove = 'remove';
const toggle = 'toggle';
const ariaAttributes = [
  {
    type: 'aria-hidden',
    init: true,
  },
  {
    type: 'aria-disabled',
    init: true,
  },
  {
    type: 'aria-selected',
    init: false,
  },
  {
    type: 'aria-expanded',
    init: false,
  },
  {
    type: 'aria-pressed',
    init: false,
  },
  {
    type: 'aria-checked',
    init: false,
  },
];

const KEY_CODES = {
  enter: 13,
  space: 32,
};

const setClass = function (elem, stateClass, behaviour) {
  if (stateClass !== 'false') {
    if (behaviour === add) {
      elem.classList.add(stateClass);
    } else if (behaviour === remove) {
      elem.classList.remove(stateClass);
    } else {
      elem.classList.toggle(stateClass);
    }
  }
};

const setAria = function (elem, behaviour) {
  ariaAttributes.forEach((ariaAttribute) => {
    const { type, init } = ariaAttribute;

    if (elem.hasAttribute(type)) {
      if (behaviour === add) {
        elem.setAttribute(type, !init);
      } else if (behaviour === remove) {
        elem.setAttribute(type, init);
      } else {
        elem.setAttribute(type, elem.getAttribute(type) !== 'true');
      }
    }
  });
};

const setTabindex = function (elem, tabindex, behaviour) {
  if (tabindex === 'true') {
    if (behaviour === add) {
      elem.setAttribute('tabindex', 0);
    } else if (behaviour === remove) {
      elem.setAttribute('tabindex', -1);
    } else {
      elem.setAttribute('tabindex', elem.getAttribute('tabindex') === '-1' ? 0 : -1);
    }
  }
};

const setState = function (parameters) {
  parameters.behaviours.forEach((behaviour, index) => {
    const elems = document.querySelectorAll(`.${parameters.targets[index]}`);
    const stateClass = parameters.states[index];
    const tabindex = parameters.tabindexes !== null ? parameters.tabindexes[index] : null;

    elems.forEach((elem) => {
      if (behaviour === add) {
        setClass(elem, stateClass, add);
        setAria(elem, add);
        setTabindex(elem, tabindex, add);
      } else if (behaviour === remove) {
        setClass(elem, stateClass, remove);
        setAria(elem, remove);
        setTabindex(elem, tabindex, remove);
      } else {
        setClass(elem, stateClass, toggle);
        setAria(elem, toggle);
        setTabindex(elem, tabindex, toggle);
      }
    });
  });
};

export default function Addstate(context) {
  const functionContext = typeof context !== 'undefined' ? context : document;
  const state = functionContext.querySelectorAll('[data-component="state"]');

  state.forEach((elem) => {
    const parameters = {
      behaviours: elem.dataset.behaviour.split(', ').filter(Boolean),
      states: elem.dataset.state.split(', ').filter(Boolean),
      tabindexes: elem.dataset.tabindex ? elem.dataset.tabindex.split(', ').filter(Boolean) : null,
      targets: elem.dataset.target.split(', ').filter(Boolean),
    };

    const preventDefault = elem.dataset.preventDefault !== 'false';

    if (parameters.states.length === 0 || parameters.targets.length === 0) {
      return;
    }

    elem.addEventListener('click', (event) => {
      if (preventDefault) event.preventDefault();

      setState(parameters);
    });

    elem.addEventListener('keydown', (event) => {
      if (event.which === KEY_CODES.enter || event.which === KEY_CODES.space) {
        if (preventDefault) event.preventDefault();

        setState(parameters);
      }
    });
  });
}
