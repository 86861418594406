var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import translate from '../../../../translate';
import CustomisationButton from './CustomisationButton';
var AddToBasketButton = function (_a) {
    var isPreorder = _a.isPreorder, isAvailable = _a.isAvailable, isInOtherBasket = _a.isInOtherBasket, isCustomisation = _a.isCustomisation, preorderDate = _a.preorderDate, ean = _a.ean, tracking = _a.tracking, popinCrossSellPath = _a.popinCrossSellPath, _b = _a.withTooltip, withTooltip = _b === void 0 ? true : _b, addedToCart = _a.addedToCart, isLoading = _a.isLoading, id = _a.id;
    var translation = 'front.product.index.cart_add';
    var addToBasketProps = {
        'data-cart-add-product': true,
    };
    if (tracking) {
        addToBasketProps['data-tracking'] = JSON.stringify(tracking);
    }
    if (popinCrossSellPath) {
        addToBasketProps['data-popin-cross-sell-path'] = popinCrossSellPath;
    }
    if (addedToCart) {
        translation = 'front.product.index.added_to_cart';
        addToBasketProps = {
            disabled: true,
        };
    }
    else if (isPreorder && isInOtherBasket) {
        translation = 'front.product.index.in_other_basket_and_preorder_cart_add';
    }
    else if (isPreorder) {
        translation = 'front.product.index.preorder_cart_add';
    }
    else if (isInOtherBasket) {
        translation = 'front.product.index.in_other_basket';
        addToBasketProps = {
            disabled: true,
        };
    }
    var Button = isCustomisation && isAvailable && !addedToCart && ean ? (React.createElement(CustomisationButton, { ean: ean }, translate(translation, {
        date: preorderDate,
    }))) : (React.createElement("button", __assign({}, addToBasketProps, { type: "button", id: id, className: "c-btn c-btn--loader c-tooltip__trigger u-w-100 js-add-cart-button", "aria-describedby": "tooltip-expedition" }),
        React.createElement("span", { className: isLoading ? 'u-visibility-hidden' : '' }, translate(translation, {
            date: preorderDate,
        })),
        isLoading && (React.createElement("div", { className: "c-loader c-loader--button c-loader--fullscreen" },
            React.createElement("div", { className: "c-loader__spinner" })))));
    if (isPreorder && withTooltip && !addedToCart) {
        return (React.createElement("span", { className: "c-tooltip c-tooltip--secondary u-w-100" },
            Button,
            React.createElement("span", { id: "tooltip-expedition", className: "c-tooltip__bubble c-tooltip__bubble--multiline-full c-tooltip__bubble--spacing", role: "tooltip", dangerouslySetInnerHTML: {
                    __html: translate('front.product.index.preorder_text', {
                        date: preorderDate,
                    }),
                } })));
    }
    return Button;
};
export default AddToBasketButton;
