var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var getBrandCountries = function () {
    var countries;
    try {
        countries = JSON.parse(document.body.dataset.countries);
    }
    catch (_a) {
        // eslint-disable-next-line no-console
        console.error("Couldn't get brand countries");
        countries = [];
    }
    return countries;
};
export var getDefaultCountry = function () {
    var defaultCountry;
    try {
        defaultCountry = JSON.parse(document.body.dataset.defaultCountry);
    }
    catch (_a) {
        // eslint-disable-next-line no-console
        console.error("Couldn't get default country data");
    }
    return defaultCountry;
};
export var getMapCountrySite = function () {
    var mapCountrySite;
    try {
        mapCountrySite = JSON.parse(document.body.dataset.mapCountrySite);
    }
    catch (_a) {
        // eslint-disable-next-line no-console
        console.error("Couldn't get default country site map");
        mapCountrySite = {};
    }
    return mapCountrySite;
};
export var getUserAgentCountry = function () {
    var userAgent = navigator.userAgent;
    var parts = userAgent.split(' ');
    var code;
    var currency;
    parts.forEach(function (part) {
        if (part.startsWith('iso2Country')) {
            code = part.split('/')[1];
        }
        if (part.startsWith('isoCurrencyGlobalE')) {
            currency = part.split('/')[1];
        }
    });
    return code && currency
        ? {
            code: code,
            currency: currency,
        }
        : undefined;
};
var FORCED_COUNTRY_NAMES = {
    HK: 'Hong Kong SAR',
    MO: 'Macau SAR',
    TW: 'Taiwan Area',
};
export var getCountryName = function (countryCode_1) {
    var args_1 = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args_1[_i - 1] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([countryCode_1], args_1, true), void 0, function (countryCode, locale) {
        var forcedName, countryName;
        if (locale === void 0) { locale = document.documentElement.lang; }
        return __generator(this, function (_a) {
            forcedName = FORCED_COUNTRY_NAMES[countryCode];
            if (forcedName)
                return [2 /*return*/, forcedName];
            try {
                // @ts-ignore See https://github.com/microsoft/TypeScript/pull/44022
                countryName = new Intl.DisplayNames([locale], { type: 'region' }).of(countryCode);
            }
            catch (_b) {
                countryName = countryCode;
            }
            return [2 /*return*/, countryName];
        });
    });
};
export var getLanguageName = function (countryCode_1) {
    var args_1 = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args_1[_i - 1] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([countryCode_1], args_1, true), void 0, function (countryCode, locale) {
        var languageName;
        if (locale === void 0) { locale = document.documentElement.lang; }
        return __generator(this, function (_a) {
            try {
                // @ts-ignore See https://github.com/microsoft/TypeScript/pull/44022
                languageName = new Intl.DisplayNames([locale], { type: 'language' }).of(countryCode);
            }
            catch (_b) {
                languageName = countryCode;
            }
            return [2 /*return*/, languageName.charAt(0).toUpperCase() + languageName.slice(1)];
        });
    });
};
// List from https://www.sezane.com/choose-country-region/
export var GLOBALE_COUNTRIES_CURRENCIES = [
    { country: 'AD', currency: 'EUR' },
    { country: 'AE', currency: 'AED' },
    { country: 'AR', currency: 'ARS' },
    { country: 'AT', currency: 'EUR' },
    { country: 'AU', currency: 'AUD' },
    { country: 'BA', currency: 'BAM' },
    { country: 'BE', currency: 'EUR' },
    { country: 'BG', currency: 'BGN' },
    { country: 'BH', currency: 'BHD' },
    { country: 'BL', currency: 'EUR' },
    { country: 'BR', currency: 'BRL' },
    { country: 'BY', currency: 'BYN' },
    { country: 'CA', currency: 'CAD' },
    { country: 'CH', currency: 'CHF' },
    { country: 'CI', currency: 'XOF' },
    { country: 'CL', currency: 'CLP' },
    { country: 'CN', currency: 'CNY' },
    { country: 'CO', currency: 'COP' },
    { country: 'CY', currency: 'EUR' },
    { country: 'CZ', currency: 'CZK' },
    { country: 'DE', currency: 'EUR' },
    { country: 'DK', currency: 'DKK' },
    { country: 'EE', currency: 'EUR' },
    { country: 'ES', currency: 'EUR' },
    { country: 'FI', currency: 'EUR' },
    { country: 'FR', currency: 'EUR' },
    { country: 'GB', currency: 'GBP' },
    { country: 'GE', currency: 'GEL' },
    { country: 'GF', currency: 'EUR' },
    { country: 'GG', currency: 'GBP' },
    { country: 'GP', currency: 'EUR' },
    { country: 'GR', currency: 'EUR' },
    { country: 'HK', currency: 'HKD' },
    { country: 'HR', currency: 'HRK' },
    { country: 'HU', currency: 'HUF' },
    { country: 'IC', currency: 'EUR' },
    { country: 'ID', currency: 'IDR' },
    { country: 'IE', currency: 'EUR' },
    { country: 'IL', currency: 'ILS' },
    { country: 'IM', currency: 'GBP' },
    { country: 'IN', currency: 'INR' },
    { country: 'IS', currency: 'ISK' },
    { country: 'IT', currency: 'EUR' },
    { country: 'JE', currency: 'GBP' },
    { country: 'JP', currency: 'JPY' },
    { country: 'KR', currency: 'KRW' },
    { country: 'KW', currency: 'KWD' },
    { country: 'LB', currency: 'LBP' },
    { country: 'LI', currency: 'CHF' },
    { country: 'LT', currency: 'EUR' },
    { country: 'LU', currency: 'EUR' },
    { country: 'LV', currency: 'EUR' },
    { country: 'MA', currency: 'MAD' },
    { country: 'MC', currency: 'EUR' },
    { country: 'MF', currency: 'EUR' },
    { country: 'MO', currency: 'MOP' },
    { country: 'MQ', currency: 'EUR' },
    { country: 'MT', currency: 'EUR' },
    { country: 'MU', currency: 'MUR' },
    { country: 'MX', currency: 'MXN' },
    { country: 'MY', currency: 'MYR' },
    { country: 'NC', currency: 'EUR' },
    { country: 'NG', currency: 'NGN' },
    { country: 'NL', currency: 'EUR' },
    { country: 'NO', currency: 'NOK' },
    { country: 'NZ', currency: 'NZD' },
    { country: 'OM', currency: 'OMR' },
    { country: 'PA', currency: 'PAB' },
    { country: 'PE', currency: 'PEN' },
    { country: 'PF', currency: 'EUR' },
    { country: 'PH', currency: 'PHP' },
    { country: 'PL', currency: 'PLN' },
    { country: 'PT', currency: 'EUR' },
    { country: 'QA', currency: 'QAR' },
    { country: 'RE', currency: 'EUR' },
    { country: 'RO', currency: 'RON' },
    { country: 'RS', currency: 'RSD' },
    { country: 'RU', currency: 'RUB' },
    { country: 'SA', currency: 'SAR' },
    { country: 'SE', currency: 'SEK' },
    { country: 'SG', currency: 'SGD' },
    { country: 'SI', currency: 'EUR' },
    { country: 'SK', currency: 'EUR' },
    { country: 'SN', currency: 'XOF' },
    { country: 'TH', currency: 'THB' },
    { country: 'TN', currency: 'TND' },
    { country: 'TR', currency: 'TRY' },
    { country: 'TW', currency: 'TWD' },
    { country: 'UA', currency: 'UAH' },
    { country: 'US', currency: 'USD' },
    { country: 'US', currency: 'USD' },
    { country: 'UY', currency: 'UYU' },
    { country: 'VC', currency: 'XCD' },
    { country: 'VN', currency: 'VND' },
    { country: 'WF', currency: 'EUR' },
    { country: 'YT', currency: 'EUR' },
    { country: 'ZA', currency: 'ZAR' },
];
