import isMobileDisplay from '../mobile/check';

/* state hover
 ========================================================================== */

export default function addStateHover(context) {
  if (isMobileDisplay()) {
    return;
  }

  const functionContext = typeof context !== 'undefined' ? context : document;
  const components = functionContext.querySelectorAll('[data-component="state-hover"]');

  const addState = (elem) => {
    const targets = document.querySelectorAll(elem.dataset.target);

    // mouse over
    const onMouseOver = () => targets.forEach(target => target.classList.add('is-visible'));

    // mouse out
    const onMouseOut = () => targets.forEach(target => target.classList.remove('is-visible'));

    // add event listeners
    elem.addEventListener('mouseover', onMouseOver);
    elem.addEventListener('mouseout', onMouseOut);

    elem.addEventListener('focus', onMouseOver);
    elem.addEventListener('blur', onMouseOut);
  };

  components.forEach(component => addState(component));
}
