/* eslint-disable no-use-before-define, no-restricted-globals, import/no-cycle */
// @ts-expect-error
import Dropdowns from 'a11y-dropdown-component';
import { VARIANT_SWITCH } from '../../events';
import addSelectDropdowns from '../dropdowns/select';
import addLazyLoad from '../lazyLoad/index';
import isMobileDisplay from '../mobile/check';
import addState from '../states/index';
import addTabs from '../tabs/index';
import zoom from '../zoom/index';
import { clearCtas, renderProductCta } from './cta/ProductCta';
import radioCheckbox from './radioCheckbox';
import showLastSeenProducts from './show';
import addStickyProductBar from './sticky';
import { crossSellOtherSizes, otherVariantsProduct, otherVariantsProductLoop } from '../sliders/sliders';
import addCollapses from '../collapse';
import { setHTMLWithScripts } from '../../utils';
export function switchVariant(variantId, shouldUpdateSlider) {
    if (shouldUpdateSlider === void 0) { shouldUpdateSlider = true; }
    if (window.variants && window.variants[variantId] && document.body.dataset.variant !== variantId) {
        var variant = window.variants[variantId];
        var productSelector = 'article[data-component="product"]';
        var mainContent = document.querySelector(productSelector);
        document.body.dataset.variant = variantId;
        if (!mainContent) {
            return;
        }
        var isMobileDisplayVar = isMobileDisplay();
        var selectedInputSize = document.querySelector("input[name=\"ean[".concat(isMobileDisplayVar ? 'mobile' : 'desktop', "][]\"][type=checkbox]:checked"));
        history.replaceState(history.state, null, variant.url + window.location.hash);
        // We need to clear mounted components before overriding the DOM to avoid zombies instances (preact doesn't know we are removing everything)
        clearCtas();
        // Update HTML and init all the things again
        setHTMLWithScripts(mainContent, variant.render, 'outer', productSelector);
        document.dispatchEvent(new CustomEvent(VARIANT_SWITCH, {
            detail: {
                variantId: variantId,
                shouldUpdateSlider: shouldUpdateSlider,
            },
        }));
        renderProductCta();
        Dropdowns.init();
        addSelectDropdowns();
        addLazyLoad();
        addTabs();
        addState();
        radioCheckbox();
        showLastSeenProducts();
        zoom();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        productSwitcher();
        addStickyProductBar();
        addCollapses();
        crossSellOtherSizes();
        if (selectedInputSize) {
            var selector = "input[name=\"ean[".concat(isMobileDisplayVar ? 'mobile' : 'desktop', "][]\"][type=checkbox][data-size=\"").concat(selectedInputSize.dataset.size, "\"]");
            if (isMobileDisplayVar) {
                selector = "".concat(selector, " + label");
            }
            var newInputSize = document.querySelector(selector);
            if (newInputSize) {
                var event_1 = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    composed: true,
                    view: window,
                });
                newInputSize.dispatchEvent(event_1);
            }
        }
        // Cross sell variants
        if (window.variantsCrossSell && window.variantsCrossSell[variantId]) {
            var variants = window.variantsCrossSell[variantId];
            var variantsSection = document.querySelector('section[data-components="variants"]');
            variantsSection.outerHTML = variants.render;
            setTimeout(function () {
                otherVariantsProduct();
                otherVariantsProductLoop();
            }, 100);
        }
    }
}
var handleProductSwitch = function (e) {
    var variantId = e.target.dataset.variantReloadId;
    if (window.variants && window.variants[variantId]) {
        e.preventDefault();
        switchVariant(variantId);
    }
};
export default function productSwitcher() {
    var variantLinks = document.querySelectorAll('a[data-variant-reload-id]');
    variantLinks.forEach(function (link) {
        link.addEventListener('click', handleProductSwitch);
    });
}
