/* navigation
========================================================================== */
var MOBILE_BREAKPOINT = 1024;
var MIN_LOGO_SPACE = {
    sezane: 200,
    octobre: 200,
};
var SHRINKED_CLASS = 'header-is-shrinked';
export default function handleNavigation() {
    var navigationItems = document.querySelectorAll('.js-navigation-item');
    var body = document.body;
    var header = document.querySelector('.js-header');
    navigationItems.forEach(function (navigationItem) {
        navigationItem.addEventListener('mouseover', function () {
            header.classList.add('is-expanded');
        });
        navigationItem.addEventListener('mouseout', function () {
            header.classList.remove('is-expanded');
        });
    });
    var nav = document.querySelector('.js-nav-submenu');
    var icons = document.querySelector('.js-header-icons');
    var headerDefault = document.querySelector('.js-header-default');
    var headerInner = document.querySelector('.c-header__inner');
    var host = window.location.host;
    var brand = host.includes('sezane') ? 'sezane' : 'octobre';
    var headerDefaultResize = new ResizeObserver(function () {
        var headerHeight = header.offsetHeight;
        var headerHeightProperty = getComputedStyle(header).getPropertyValue('--header-dynamic-height');
        if (headerHeight !== parseInt(headerHeightProperty, 10)) {
            header.style.setProperty('--header-dynamic-height', "".concat(headerHeight, "px"));
        }
        var spaceForLogo = headerInner.offsetWidth - nav.offsetWidth - icons.offsetWidth;
        var hasShrinkedClass = body.classList.contains(SHRINKED_CLASS);
        if (window.innerWidth > MOBILE_BREAKPOINT) {
            if (spaceForLogo >= MIN_LOGO_SPACE[brand]) {
                hasShrinkedClass && body.classList.remove(SHRINKED_CLASS);
            }
            else {
                !hasShrinkedClass && body.classList.add(SHRINKED_CLASS);
            }
        }
        else {
            hasShrinkedClass && body.classList.remove(SHRINKED_CLASS);
        }
    });
    if (headerDefault) {
        headerDefaultResize.observe(headerDefault);
    }
}
