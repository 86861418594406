/* accordions
 ========================================================================== */

import addAccordion, { initAccordions, setAutoHeights } from './lib';

// init accordions based on data components
initAccordions();

// set auto heights
setAutoHeights();

// programmatically add accordions
export default function addAccordions() {
  // submenu
  const submenu = document.querySelector('.js-nav-submenu');
  const submenuItems = document.querySelectorAll('.js-nav-submenu-item');
  const submenuLinks = document.querySelectorAll('.js-nav-submenu-link');

  submenuItems.forEach((submenuItem) => {
    addAccordion(submenuItem.id, {
      breakpoint: 1024,
    });
  });

  if (submenu) {
    const clickEvent = new MouseEvent('click');

    submenuLinks.forEach((submenuLink) => {
      submenuLink.addEventListener('click', () => {
        const childrenLinks = submenuLink.parentNode.nextElementSibling.querySelectorAll('.js-nav-submenu-link');

        if (childrenLinks.length !== 0) {
          setTimeout(() => {
            childrenLinks.forEach((childrenLink) => {
              if (submenuLink.getAttribute('aria-expanded') !== 'false' && childrenLink.getAttribute('aria-expanded') === 'false') {
                childrenLink.dispatchEvent(clickEvent);
              } else if (submenuLink.getAttribute('aria-expanded') === 'false' && childrenLink.getAttribute('aria-expanded') !== 'false') {
                childrenLink.dispatchEvent(clickEvent);
              }
            });
          }, 0);
        }
      });
    });
  }
}
