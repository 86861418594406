/* zoom
  ========================================================================== */
export default function zoom() {
    var zoomContainers = document.querySelectorAll('[data-role="zoom"]');
    var currentlyZoomedContainer = null;
    zoomContainers.forEach(function (zoomContainer) {
        var positionX;
        var positionY;
        var onMousemove = function (event) {
            positionX = event.offsetX / zoomContainer.offsetWidth * 100;
            positionY = event.offsetY / zoomContainer.offsetHeight * 100;
            zoomContainer.style.setProperty('--zoom-position-x', "".concat(positionX, "%"));
            zoomContainer.style.setProperty('--zoom-position-y', "".concat(positionY, "%"));
        };
        var onClick = function (event) {
            event.preventDefault();
            if (currentlyZoomedContainer === zoomContainer) {
                // If clicking on the already zoomed element, unzoom it
                zoomContainer.classList.remove('is-visible');
                currentlyZoomedContainer = null;
            }
            else {
                // Unzoom the previously zoomed element and zoom the new element
                currentlyZoomedContainer === null || currentlyZoomedContainer === void 0 ? void 0 : currentlyZoomedContainer.classList.remove('is-visible');
                zoomContainer.classList.add('is-visible');
                currentlyZoomedContainer = zoomContainer;
            }
        };
        zoomContainer.addEventListener('mousemove', onMousemove);
        zoomContainer.addEventListener('click', onClick);
    });
}
