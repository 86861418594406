export default function autoRefresh() {
    if (document.querySelector('*[data-component="product"]')) {
        var pageLoadedTimestamp_1 = Date.now() / 1000;
        var dayInSeconds_1 = 86400;
        document.addEventListener('visibilitychange', function () {
            if (!document.hidden) {
                if (pageLoadedTimestamp_1 + dayInSeconds_1 < Date.now() / 1000) {
                    window.location.reload();
                }
            }
        }, false);
    }
}
