/* product/showLastSeen
 ========================================================================== */

import lastSeen from './lastSeen';
import addLazyLoad from '../lazyLoad';

/**
 * @param lastSeenComponent
 * @param {string} site
 * @param {string} currentProductHash
 */
function show(lastSeenComponent, site, currentProductHash = null) {
  const lastSeenWrapper = lastSeenComponent.querySelector('ul');
  let lastSeenProducts = lastSeen.get(site);

  // don't show current product in lastSeen tab
  if (currentProductHash) {
    lastSeenProducts = lastSeenProducts.filter(current => currentProductHash !== current.hash);
  }

  // max 3 elements per lastSeen tab
  lastSeenProducts = lastSeenProducts.slice(0, 3);

  if (lastSeenProducts.length > 0) {
    lastSeenProducts.forEach((current) => {
      const temp = document.createElement('textarea');
      temp.innerHTML = current.template;
      lastSeenWrapper.innerHTML += temp.value;
    });
  } else {
    // delete tab & panel when no last seen product to show
    lastSeenWrapper.remove();
    lastSeenComponent.parentNode.querySelector('#tab-more-products-last').remove();
  }
}

export default function showLastSeenProducts() {
  const lastSeenComponent = document.querySelector('*[data-component="lastseen"]');

  if (lastSeenComponent && lastSeenComponent.querySelector('ul')) {
    const cartComponent = document.querySelector('*[data-component="cart"]');
    const productComponent = document.querySelector('*[data-component="product"]');

    if (cartComponent && cartComponent.dataset.site) {
      show(lastSeenComponent, cartComponent.dataset.site);
    } else if (productComponent && productComponent.dataset.productHash) {
      show(lastSeenComponent, productComponent.dataset.site, productComponent.dataset.productHash);
    } else if (productComponent && productComponent.dataset.site) {
      show(lastSeenComponent, productComponent.dataset.site);
    }

    setTimeout(addLazyLoad, 0);
  }
}
