/* switch images
 ========================================================================== */

export default function switchImages() {
  const components = document.querySelectorAll('[data-role="switch-image"]');
  const activeClassName = 'is-visible';
  const delay = 2500;

  components.forEach((component) => {
    const images = component.querySelectorAll('[data-image]');
    let currentImageID = 0;

    const setImages = () => {
      let nextImageID = currentImageID + 1;

      images.forEach((image, index) => {
        image.classList.remove(activeClassName);

        if (index === currentImageID) nextImageID = index < images.length - 1 ? index + 1 : 0;
      });

      currentImageID = nextImageID;
      images[currentImageID].classList.add(activeClassName);
    };

    setInterval(setImages, delay);
  });
}
