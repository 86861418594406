/* radio checkbox
 ========================================================================== */
export default function radioCheckbox() {
  const fieldsets = document.querySelectorAll('[data-role="radio-checkbox"]');
  const selectedSize = decodeURI(document.location.hash.replace('#size-', ''));

  const onChange = (radioButtons, event) => {
    radioButtons.forEach((radioButton) => {
      if (radioButton !== event.target) radioButton.checked = false;
      if (radioButton === event.target && radioButton.checked === false) radioButton.checked = true;
    });
  };

  fieldsets.forEach((fieldset) => {
    const radioButtons = fieldset.querySelectorAll('input[type="checkbox"]');
    let foundSize = false;

    radioButtons.forEach((radioButton) => {
      if (radioButton.dataset.size && selectedSize) {
        if (radioButton.dataset.size === selectedSize) {
          radioButton.checked = true;
          foundSize = true;
        } else {
          radioButton.checked = false;
        }
      }

      radioButton.addEventListener('change', onChange.bind(this, radioButtons));
    });

    // Find first available size and select it
    if (!foundSize) {
      const availableSize = fieldset.querySelector('input[type="checkbox"][data-item-available="1"]');

      if (availableSize) {
        availableSize.checked = true;
      }
    } else {
      const dropdownText = document.querySelector('#dropdown-trigger-size .c-dropdown__text');
      if (dropdownText) {
        dropdownText.textContent = selectedSize;
      }
    }
  });
}
