/* reveal dialog
 ========================================================================== */

// vendors
import Dialogs from './a11y-dialog-component';

// reveal dialog
export default function addRevealDialog(selector, delay) {
  const dialogs = document.querySelectorAll(selector);

  dialogs.forEach((dialog) => {
    const dialogID = dialog.id;

    Dialogs.create(dialogID, { show: true });
    window.setTimeout(() => Dialogs.hide(dialogID), delay);
  });
}
