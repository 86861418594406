/* add sliders
 ========================================================================== */

import * as addSlider from './sliders';

export default function addSliders() {
  // add horizontal slider
  addSlider.horizontal();

  // add vertical slider
  addSlider.vertical();

  if (document.querySelector('.js-slider-product')) {
    // add product slider
    addSlider.product();
  }

  // add crosseling product slider
  addSlider.crosselingProduct();

  // add centered crosseling product slider
  addSlider.centeredCrosselingProduct();

  // add loop slider
  addSlider.loop();

  if (document.querySelector('.js-slider-loop-autoplay')) {
    // add loop autoplay slider
    addSlider.loopAutoplay();
  }

  if (document.querySelector('.js-slider-faq')) {
    // add faq slider
    addSlider.faqSlider();
  }

  if (document.querySelector('.js-slider-cms-slider-3-visible-center')) {
    // add cms slider-3-visible-center
    addSlider.cmsSlider3Center();
  }

  if (document.querySelector('.js-slider-cms-slider-2-center')) {
    // add cms slider-2-center
    addSlider.cmsSlider2Center();
  }

  if (document.querySelector('.js-slider-cms-slider-4-visible')) {
    // add cms slider-4-visible
    addSlider.cmsSlider4();
  }

  if (document.querySelector('.js-slider-cms-slider-5-visible')) {
    // add cms slider-5-visible
    addSlider.cmsSlider5();
  }

  if (document.querySelector('.js-slider-cms-slider-7-visible')) {
    // add cms slider-7-visible
    addSlider.cmsSlider7();
  }

  if (document.querySelector('.js-slider-block-product')) {
    // add slider to cms block product
    addSlider.cmsBlockProduct();
  }

  if (document.querySelector('.js-slider-lbe-slider-5')) {
    // add cms lbe-slider-5
    addSlider.lookbookEditoSlider5();
  }

  addSlider.otherVariantsProduct();
  addSlider.otherVariantsProductLoop();
  addSlider.crossSellOtherSizes();
}
