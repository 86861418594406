import React from 'react';
import translate from '../../translate';
var PopinEdging = function (_a) {
    var popin = _a.popin;
    return (React.createElement("div", { id: "dialog-edging", className: "c-dialog c-dialog--sm-bis c-dialog--border-secondary c-dialog--box-xxl c-dialog--box-n-top", "data-popin-id": popin.uid, "data-only-once": popin.oncePerSession },
        React.createElement("div", { className: "c-dialog__inner c-overlay" },
            React.createElement("button", { type: "button", "aria-label": translate('front.popin.close'), className: "c-dialog__close u-btn-unstyled", "data-dialog-hide": true },
                React.createElement("i", { className: "c-icon c-dialog__icon u-color-white@sm u-text-icon-xxs@sm" },
                    React.createElement("svg", { width: "22", height: "22", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53.34 53.34", className: "c-icon__svg", "aria-hidden": "true" },
                        React.createElement("path", { d: "M53.34 7.64L45.7 0 26.67 19.03 7.64 0 0 7.64l19.03 19.03L0 45.7l7.64 7.64 19.03-19.03L45.7 53.34l7.64-7.64-19.03-19.03L53.34 7.64z" })))),
            React.createElement("div", { className: "c-dialog__box u-text-center" },
                React.createElement("h4", { id: "dialog-edging-title", className: "u-h4-bis u-text-uppercase" }, popin.title),
                React.createElement("div", { className: "s-cms-popin-edging u-mt-xs-sm u-mt-xs@sm" },
                    React.createElement("div", { id: "dialog-edging-desc", className: "s-wysiwyg", dangerouslySetInnerHTML: { __html: popin.content } })),
                popin.link && (React.createElement("a", { href: popin.link, className: popin.ctaText ? 'c-btn u-mt-md u-mt-sm-bis@sm' : 'c-overlay__link', target: popin.target }, popin.ctaText ? (popin.ctaText) : (React.createElement("span", { className: "u-visually-hidden" }, translate('front.popin.default_cta_text')))))))));
};
export default PopinEdging;
