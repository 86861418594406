/* tabs
 ========================================================================== */
export const TAB_SHOW_EVENT = 'tab-show';

export default function addTabs() {
  const tabPanels = document.querySelectorAll('[data-component="tabs"]');

  tabPanels.forEach((tabPanel) => {
    const tabs = tabPanel.querySelectorAll('[role=tab]');
    const panels = tabPanel.querySelectorAll('[role=tabpanel]');

    const show = (tab, panel) => {
      tab.setAttribute('aria-selected', true);
      tab.setAttribute('tabindex', 0);
      panel.setAttribute('aria-hidden', false);
    };

    const hide = (tab, panel) => {
      tab.setAttribute('aria-selected', false);
      tab.setAttribute('tabindex', -1);
      panel.setAttribute('aria-hidden', true);
    };

    const render = (currentTab, currentPanel) => {
      tabs.forEach((tab, index) => {
        if (tab !== currentTab) {
          hide(tab, panels[index]);
        } else {
          show(currentTab, currentPanel);
        }
      });
    };

    tabs.forEach((tab, index) => {
      tab.addEventListener('click', (event) => {
        const panel = panels[index];
        event.preventDefault();
        render(tab, panel);
        window.history.pushState({ tab: tab.id }, null);
        panel.dispatchEvent(new CustomEvent(TAB_SHOW_EVENT, { detail: { id: panel.id } }));
      });

      if (window.history.state?.tab === tab.id) {
        render(tab, panels[index]);
      }
    });
  });
}
